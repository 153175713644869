import React, { useContext } from 'react'
import { Trans } from 'react-i18next'
import SearchQueryBox from '../../../components/Search/QueryBox'
import { Images } from '../../../components/Images'
import MenuButton from '../../../components/Menu/Button'
import Menu from '../../../components/Menu'
import { useStore } from '../../../hooks/useStore'
import AppStore from '../../../modules/app/store'
import { ConfigContext } from '../../../modules/config/context'
import { getServiceRoute } from '../../../modules/router/service'
import { Service } from '../../../modules/config/model'
import { Home, Logo, CatchWord } from './styled'

const HomeRoute: React.FC = () => {
  const config = useContext(ConfigContext)
  const appStore = useStore(AppStore)

  return (
    <>
      <Menu compensationWidth={appStore.scrollBarCompensation} isHome={true} />
      <MenuButton compensationWidth={appStore.scrollBarCompensation} isHome={true} />
      <Home>
        <Logo data-testid="logo">
          <a href={getServiceRoute(config.service, config.country)}>
            <Images.LogoHome />
            {config.service == Service.Ulozto && (
              <>
                <span className="stamp">Ulož.to Disk</span>
              </>
            )}
            {config.service == Service.Pinkfile && (
              <>
                <span className="stamp">PINKfile.cz</span>
              </>
            )}
          </a>
        </Logo>
        <SearchQueryBox isHome={true} />
        <CatchWord>
          <Trans i18nKey={'components.Home.catchWord'} />
        </CatchWord>
      </Home>
    </>
  )
}

export default HomeRoute
