import React from 'react'

const LogoPornHomeSvg: React.FC = () => (
  <svg viewBox="0 0 650 380" width="1em" height="1em" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#f6a7b5"
      d="m66 354.8c-4.9 0-9.4-0.8-13.4-2.4-4.1-1.6-7.6-3.8-10.6-6.8-3-2.9-5.4-6.3-7-10.2-1.7-3.9-2.5-8.2-2.5-12.9s0.8-9 2.5-12.9 4-7.3 7.1-10.2c3-2.9 6.6-5.2 10.7-6.8s8.6-2.4 13.5-2.4c5 0 9.6 0.8 13.7 2.4s7.7 4.1 10.6 7.3l-5.6 5.6c-2.6-2.5-5.4-4.4-8.4-5.5-3-1.2-6.3-1.8-9.9-1.8s-7 0.6-10.1 1.8-5.8 2.9-8 5.1c-2.3 2.2-4 4.7-5.2 7.7s-1.8 6.2-1.8 9.7c0 3.4 0.6 6.6 1.8 9.6s3 5.6 5.2 7.8c2.3 2.2 4.9 3.9 8 5.1s6.4 1.8 10 1.8c3.4 0 6.6-0.5 9.7-1.6s6-2.8 8.6-5.3l5.1 6.9c-3.1 2.6-6.8 4.6-11 6-4.3 1.3-8.6 2-13 2zm15.2-9.2v-23.4h8.7v24.6l-8.7-1.2zm54.4 9.2c-4.8 0-9.3-0.8-13.4-2.4s-7.7-3.9-10.7-6.8-5.4-6.3-7-10.2c-1.7-3.9-2.5-8.2-2.5-12.8s0.8-8.9 2.5-12.8 4-7.3 7-10.2 6.6-5.2 10.6-6.8c4.1-1.6 8.6-2.4 13.4-2.4s9.2 0.8 13.3 2.4 7.6 3.8 10.6 6.8c3 2.9 5.4 6.3 7 10.2 1.7 3.9 2.5 8.2 2.5 12.9s-0.8 9-2.5 12.9-4 7.3-7 10.2-6.6 5.2-10.6 6.8c-4 1.4-8.4 2.2-13.2 2.2zm0-8.1c3.5 0 6.7-0.6 9.7-1.8s5.6-2.9 7.8-5.1 3.9-4.8 5.1-7.7 1.8-6.2 1.8-9.7-0.6-6.7-1.8-9.7c-1.2-2.9-2.9-5.5-5.1-7.7s-4.8-3.9-7.8-5.1-6.2-1.8-9.7-1.8-6.8 0.6-9.8 1.8-5.6 2.9-7.8 5.1-4 4.8-5.2 7.7-1.8 6.2-1.8 9.7 0.6 6.7 1.8 9.7c1.2 2.9 3 5.5 5.2 7.7s4.8 3.9 7.8 5.1 6.3 1.8 9.8 1.8zm40.9 7.3v-6.1l42.5-52.7 1 3.5h-42.8v-7.8h51v6.1l-42.3 52.7-1.1-3.5h44.6v7.8h-52.9zm92.7 0.8c-4.8 0-9.3-0.8-13.4-2.4s-7.7-3.9-10.7-6.8-5.4-6.3-7-10.2c-1.7-3.9-2.5-8.2-2.5-12.8s0.8-8.9 2.5-12.8 4-7.3 7-10.2 6.6-5.2 10.6-6.8c4.1-1.6 8.6-2.4 13.4-2.4s9.2 0.8 13.3 2.4 7.6 3.8 10.6 6.8c3 2.9 5.4 6.3 7 10.2 1.7 3.9 2.5 8.2 2.5 12.9s-0.8 9-2.5 12.9-4 7.3-7 10.2-6.6 5.2-10.6 6.8c-4 1.4-8.4 2.2-13.2 2.2zm0-8.1c3.5 0 6.7-0.6 9.7-1.8s5.6-2.9 7.8-5.1 3.9-4.8 5.1-7.7 1.8-6.2 1.8-9.7-0.6-6.7-1.8-9.7c-1.2-2.9-2.9-5.5-5.1-7.7s-4.8-3.9-7.8-5.1-6.2-1.8-9.7-1.8-6.8 0.6-9.8 1.8-5.6 2.9-7.8 5.1-4 4.8-5.2 7.7-1.8 6.2-1.8 9.7 0.6 6.7 1.8 9.7c1.2 2.9 3 5.5 5.2 7.7s4.8 3.9 7.8 5.1 6.2 1.8 9.8 1.8z"
    />
    <path
      fill="currentColor"
      d="m346.2 354h-4.6v-63.1h42.2v4.1h-37.6v59zm-0.5-32h34.2v4.1h-34.2v-4.1zm53.6-26.5c-1 0-1.9-0.4-2.6-1.1s-1.1-1.6-1.1-2.5c0-1 0.4-1.9 1.1-2.6s1.6-1.1 2.6-1.1c1.1 0 2 0.3 2.7 1s1 1.5 1 2.6c0 1-0.3 1.9-1 2.6s-1.6 1.1-2.7 1.1zm-2.2 58.5v-47h4.5v47h-4.5zm46.5-47.4c3.8 0 7.2 0.7 10.1 2.2s5.1 3.7 6.7 6.7 2.4 6.7 2.4 11v27.5h-4.5v-27.1c0-5.3-1.3-9.3-4-12.1s-6.4-4.1-11.2-4.1c-3.7 0-6.9 0.8-9.6 2.3s-4.8 3.6-6.2 6.3-2.1 6-2.1 9.7v25h-4.5v-47h4.3v13l-0.6-1.4c1.4-3.7 3.8-6.7 7.1-8.8 3.2-2.1 7.3-3.2 12.1-3.2zm55.8 47.8c-4.4 0-8.3-1-11.9-3-3.5-2-6.3-4.8-8.3-8.4s-3.1-7.8-3.1-12.4c0-4.7 1-8.9 3.1-12.5 2-3.6 4.8-6.4 8.3-8.4s7.5-3 11.9-3c4.1 0 7.9 1 11.2 2.9s6 4.7 7.9 8.2c2 3.5 2.9 7.8 2.9 12.8 0 4.9-1 9.1-2.9 12.7s-4.6 6.4-7.9 8.3c-3.2 1.8-7 2.8-11.2 2.8zm0.3-4.1c3.6 0 6.8-0.8 9.7-2.5s5.1-4 6.8-7 2.5-6.5 2.5-10.4c0-4-0.8-7.4-2.5-10.4s-3.9-5.3-6.8-6.9c-2.9-1.7-6.1-2.5-9.7-2.5s-6.8 0.8-9.7 2.5-5.1 4-6.8 6.9c-1.7 3-2.5 6.4-2.5 10.4 0 3.9 0.8 7.4 2.5 10.4s3.9 5.3 6.8 7 6.1 2.5 9.7 2.5zm19 3.7v-15.4l0.8-8.2-0.9-8.2v-35.1h4.5v66.9h-4.4zm42.6 0.4c-4.8 0-8.9-1-12.6-3.1-3.6-2-6.5-4.9-8.6-8.5s-3.1-7.7-3.1-12.4 1-8.8 2.9-12.4c2-3.6 4.6-6.4 8.1-8.4 3.4-2 7.3-3.1 11.5-3.1 4.3 0 8.1 1 11.5 3s6.1 4.8 8 8.3c2 3.6 2.9 7.7 2.9 12.4v1.3h-41.6v-3.5h39.1l-1.8 1.7c0.1-3.7-0.7-7-2.3-9.9s-3.7-5.2-6.4-6.9-5.8-2.5-9.4-2.5c-3.5 0-6.6 0.8-9.3 2.5s-4.9 3.9-6.4 6.9c-1.6 2.9-2.3 6.2-2.3 10v0.8c0 3.8 0.9 7.3 2.6 10.2s4.1 5.3 7.1 6.9c3 1.7 6.4 2.5 10.3 2.5 3 0 5.8-0.5 8.4-1.6s4.8-2.7 6.6-5l2.6 3c-2 2.5-4.6 4.4-7.7 5.7-3.1 1.5-6.5 2.1-10.1 2.1zm34.7-0.4v-47h4.3v12.9l-0.5-1.4c1.3-3.8 3.5-6.7 6.7-8.7 3.1-2 7-3.1 11.7-3.1v4.4h-1c-5.2 0-9.3 1.6-12.3 4.9-2.9 3.3-4.4 7.8-4.4 13.7v24.3h-4.5z"
    />
    <path
      fill="#f6a7b5"
      d="m250 185.7 27.2-48.5 25.3 34.5-18.9 33.6-33.6-19.6zm143.5-10.7-15.1 23.3 21.2-12.4-6.1-10.9zm-68.7-122.4-44.4 78.9 25.3 34.5 41.4-73.7s-22.3-39.7-22.3-39.7zm29.1 39.8 40 71.1 27.1-41.8v-60.4l-37.4-21.8-29.7 52.9zm-44.3 78.9 34.4 47 23.1-13.5 23.2-35.8-39.7-70.7-41 73zm15.2-130.8 15-26.6-14.8-8.6-15.1 8.8 14.9 26.4zm72.4 128.9 7.6 13.5 16.2-9.5v-40.8l-23.8 36.8zm-90.8 7.6-17.6 31.3 36.2 21.1 13.9-8.1-32.5-44.3zm-77.4-115.7 47.6 65 44.8-79.7-16.6-29.5-75.8 44.2zm115.9-44.4-16.7 29.7 22.3 39.8 28-49.8-33.6-19.7zm-71.5 115.1-44.4-60.7v102.1l15.9 9.3 28.5-50.7z"
    />
  </svg>
)

export { LogoPornHomeSvg }
