import styled, { css } from 'styled-components'
import mixins from '../../../constants/GlobalStyleMixins'

export const DeprecatedBrowsers = styled.div`
  padding: 15px;
  background-color: ${({ theme }) => theme.color.red};
  color: ${({ theme }) => theme.color.white};
`

export const Header = styled.header<{
  $containerHeight: number
  $hasFilters?: boolean
  $compensationWidth: number
}>`
  position: sticky;
  z-index: 20;
  top: 0;
  display: block;
  background-color: ${({ theme }) => theme.color.bg.shade5};
  transition: top 0.2s linear, box-shadow 0.2s linear;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: left;
  }

  .top {
    display: grid;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    gap: 10px;
    ${({ $hasFilters }) =>
      $hasFilters
        ? css`
            padding: 10px 10px;
            grid-template-columns: 45px 1fr 45px;
          `
        : css`
            padding: 13px 10px;
            grid-template-columns: 45px 1fr 45px;
          `};

    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.xl + 1}px) {
      ${({ $hasFilters }) =>
        $hasFilters
          ? css`
              padding: 19px 20px 5px 20px;
              grid-template-columns: 150px 1fr 150px;
            `
          : css`
              padding: 20px;
              grid-template-columns: 150px 1fr 150px;
            `};
    }
  }
  .middle {
    display: block;
  }
  &.down {
    top: -${({ $containerHeight }) => $containerHeight + 30}px;
  }
  &.shadow {
    box-shadow: 0 5px 5px -3px ${({ theme }) => theme.color.shadow};
  }
`

export const Logo = styled.div`
  a {
    position: relative;
    display: block;
    height: 35px;

    @media screen and (min-width: 0px) and (max-width: ${({ theme }) => theme.layout.sizes.xl}px) {
      text-align: center;
    }
    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.xl + 1}px) {
      height: 40px;
    }

    .stamp {
      position: absolute;
      display: block;
      width: 100%;
      color: ${({ theme }) => theme.color.text};
      font-size: 7px;
      font-weight: 600;

      @media screen and (min-width: 0px) and (max-width: ${({ theme }) => theme.layout.sizes.xl}px) {
        bottom: -2px;
        left: 0;
        text-align: center;
      }
      @media screen and (min-width: ${({ theme }) => theme.layout.sizes.xl + 1}px) {
        right: 18px;
        bottom: -2px;
        text-align: right;
      }
    }

    svg {
      ${mixins.logo}
    }

    &.service {
      @media screen and (max-width: ${({ theme }) => theme.layout.sizes.xl}px) {
        svg {
          height: 25px;
        }
      }
    }
  }
`

export const Main = styled.main<{
  $setMinHeight: number
  $hasFilters?: boolean
}>`
  display: grid;
  min-height: ${({ $hasFilters, $setMinHeight }) => ($hasFilters ? $setMinHeight + 'px' : 'auto')};
  padding-top: 20px;
  background-color: ${({ theme }) => theme.color.bg.main};
`

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: ${({ theme }) => theme.color.bg.shade5};
`

export const WrapperInner = styled.div`
  position: relative;
  z-index: 10;
  height: 100%;

  &.advertisement-branding {
    position: relative;
    width: 100%;
    max-width: ${({ theme }) => theme.layout.width}px;
    margin: 0 auto;

    @media screen and (min-width: 521px) {
      margin-top: calc(21% - 5px);
    }
    @media screen and (min-width: ${({ theme }) => theme.layout.sizes.xl}px) {
      margin-top: 210px;
    }
  }
`
