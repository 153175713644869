import { ApiRequestPromise, ApiResponse, request } from '../api/request'
import { SessionId } from '../user/model'
import { Service } from '../config/model'
import { MaxQueryLength, SearchQuery } from '../search/model'
import { ISearchSuggestItem } from './model'

export interface GetSearchSuggestResponse extends ApiResponse {
  data: {
    items: ISearchSuggestItem[]
  }
}

export const getSearchSuggest = (
  sessionId: SessionId,
  service: Service,
  query: SearchQuery
): ApiRequestPromise<GetSearchSuggestResponse> => {
  // truncate query silently, if limit is exceeded
  const url = `v1/search-suggest?service=${encodeURIComponent(service)}&query=${encodeURIComponent(query.substring(0, MaxQueryLength))}`

  return request(url, sessionId) as ApiRequestPromise<GetSearchSuggestResponse>
}
