import React, { useContext, useState } from 'react'
import { HotKeys, KeyMap } from 'react-hotkeys'
import { useTranslation } from 'react-i18next'
import Icon from '../Icons'
import { getTheme, setTheme, setThemeBodyClass, Theme } from '../../modules/config/theme'
import { ConfigContext } from '../../modules/config/context'
import { Service } from '../../modules/config/model'
import { Button, Switch } from './styled'

interface Props {
  onChange?: () => void
}

const SwitchTheme: React.FC<Props> = ({ onChange }) => {
  const config = useContext(ConfigContext)
  const { t } = useTranslation()
  const [switchState, setSwitchState] = useState(getTheme(config.service))

  const handleThemeChange = () => {
    if (switchState === Theme.Dark) {
      setTheme(Theme.Light)
      setSwitchState(Theme.Light)
      setThemeBodyClass(Theme.Light)
    } else {
      setTheme(Theme.Dark)
      setSwitchState(Theme.Dark)
      setThemeBodyClass(Theme.Dark)
    }
    onChange && onChange()
  }

  if (config.service == Service.Pinkfile) {
    return null
  }

  const keyMap: KeyMap = {
    ENTER: 'enter',
  }
  const handlers = {
    ENTER: () => handleThemeChange(),
  }

  return (
    <Switch>
      <strong>{t('components.Menu.settings')}</strong>
      <HotKeys keyMap={keyMap} handlers={handlers}>
        <Button selected={switchState} onClick={handleThemeChange} tabIndex={0}>
          {switchState === Theme.Dark ? (
            <>
              <Icon.ThemeLight /> <span data-testid="menu-theme-light">{t('components.Theme.dark')}</span>
            </>
          ) : (
            <>
              <Icon.ThemeDark /> <span data-testid="menu-theme-dark">{t('components.Theme.light')}</span>
            </>
          )}
        </Button>
      </HotKeys>
    </Switch>
  )
}

export default SwitchTheme
