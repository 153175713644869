import { BehaviorSubject } from 'rxjs'
import { AdvertPosition } from './model'

export interface AdvertStoreState {
  readonly areAllowed: boolean
  readonly adverts: {
    [AdvertPosition.Branding]: boolean
    [AdvertPosition.BottomPopUp]: boolean
    [AdvertPosition.MiddleList]: boolean
  }
}

const AdvertStoreDefaults: AdvertStoreState = {
  areAllowed: false,
  adverts: {
    [AdvertPosition.Branding]: false,
    [AdvertPosition.BottomPopUp]: false,
    [AdvertPosition.MiddleList]: false,
  },
}

const AdvertStoreSubject = new BehaviorSubject(AdvertStoreDefaults)

const updateStore = (value: Partial<AdvertStoreState>) => AdvertStoreSubject.next({ ...AdvertStoreSubject.value, ...value })

const AdvertStore = {
  getValue: (): AdvertStoreState => AdvertStoreSubject.value,
  subscribe: (next: (value: AdvertStoreState) => void) => AdvertStoreSubject.subscribe(next),
  reset: () => AdvertStoreSubject.next(AdvertStoreDefaults),
  setAreAllowed: (areAllowed: boolean) => updateStore({ areAllowed }),
  setVisibility: (position: AdvertPosition, isVisible: boolean) =>
    updateStore({ adverts: { ...AdvertStoreSubject.value.adverts, [position]: isVisible } }),
}

export default AdvertStore
