import React from 'react'
import { useTranslation } from 'react-i18next'
import { Service } from '../../modules/config/model'
import { Country } from '../../modules/language/model'
import Icon from '../Icons'
import { useStore } from '../../hooks/useStore'
import SearchStore from '../../modules/search/store'
import { getServiceRoute } from '../../modules/router/service'
import { Banner } from './styled'

const BannerPinkFile: React.FC = () => {
  const { t } = useTranslation()
  const searchStore = useStore(SearchStore)

  const url = getServiceRoute(Service.Pinkfile, Country.CZ) + '?' + new URLSearchParams({ query: searchStore.searchQuery }).toString()

  return (
    <Banner
      onClick={(e) => {
        e.preventDefault()
        window.location.href = url
      }}
      data-testid="banner-pf"
    >
      <div>
        <Icon.Porn />
      </div>
      <div>
        <strong>{t('components.BannerPorn.claim')}</strong> &nbsp;
        {t('components.BannerPorn.try')}&nbsp;
        <u>{t('components.BannerPorn.button', { name: 'PINKfile.cz' })}</u>
      </div>
    </Banner>
  )
}

export default BannerPinkFile
