import React, { useContext } from 'react'
import FileIcon from '../../../Icons/fileIcon'
import { File } from '../../../../modules/file/model'
import { shouldDisplayThumbnail } from '../helpers'
import { ConfigContext } from '../../../../modules/config/context'
import UserContext from '../../../../modules/user/context'
import AppContext from '../../../../modules/app/context'
import { createFileDetailLink } from '../../../../modules/file/api'
import { Service } from '../../../../modules/config/model'
import SlideShow from './SlideShow'
import { Image, ThumbLink, ThumbWrapper } from './styled'
import VideoPreview from './VideoPreview'

interface Props {
  file: File
  itemWidth: number
  itemRadius: number
}

const Thumb: React.FC<Props> = ({ file, itemWidth, itemRadius }) => {
  const config = useContext(ConfigContext)
  const user = useContext(UserContext)
  const app = useContext(AppContext)

  const fileLink = createFileDetailLink(
    user.sessionId,
    config.service,
    config.language,
    config.country,
    app.platform,
    app.backDomain,
    file.url,
    file.id || null
  )

  if (!shouldDisplayThumbnail(file, config.service)) {
    return null
  }

  const renderComponent = () => {
    const preferPreview = window.Config.PREFER_PREVIEW
    const isUloztoPornfile = file.id && [Service.Ulozto, Service.Pinkfile].includes(config.service)
    const hasVideoPreview = file.preview && file.preview.video
    const hasImagePreview = file.preview && (file.preview.smallImage || file.preview.largeImage)
    const hasSlideshow = file.thumbnail && file.thumbnail.images.length > 0
    const hasImage = file.thumbnail && file.thumbnail.images.length === 1
    const isStreamable = !!file.format?.streamingTypes?.length

    if (preferPreview && isUloztoPornfile && hasVideoPreview) {
      return <VideoPreview id={file.id} preview={file.preview} fileName={file.name} isStreamable={isStreamable} />
    } else if (preferPreview && isUloztoPornfile && hasImagePreview) {
      return (
        <ThumbWrapper className="wrapper">
          <Image src={file.preview.smallImage || file.preview.largeImage} alt={file.name} loading="lazy" />
        </ThumbWrapper>
      )
    } else if (isUloztoPornfile && hasSlideshow) {
      return <SlideShow id={file.id} thumbnail={file.thumbnail} alt={file.name} isStreamable={isStreamable} />
    } else if (!isUloztoPornfile && hasImage) {
      return (
        <ThumbWrapper className="wrapper">
          <Image src={file.thumbnail.images[0].url} alt={file.name} loading="lazy" />
        </ThumbWrapper>
      )
    } else {
      return (
        <ThumbWrapper className="wrapper">
          <FileIcon extension={file.extension} size={'75'} />
        </ThumbWrapper>
      )
    }
  }

  return (
    <ThumbLink className={'image ' + (file.thumbnail ? 'thumb' : 'light')} href={fileLink} $itemWidth={itemWidth} $itemRadius={itemRadius}>
      {renderComponent()}
    </ThumbLink>
  )
}

export default Thumb
