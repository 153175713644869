const RANDOM_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
const BLOCK_SIZE = 3

const randomNumber = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1) + min)

const randomString = (length: number): string => {
  let result = ''

  for (let i = 0; i < length; i++) {
    result += RANDOM_CHARS.charAt(Math.floor(Math.random() * RANDOM_CHARS.length))
  }

  return result
}

// https://hellodevworld.com/365-days-of-coding/rot13-cipher-javascript-solution
const rot13 = (message: string): string => {
  return message.replace(/[a-z]/gi, (letter) => String.fromCharCode(letter.charCodeAt(0) + (letter.toLowerCase() <= 'm' ? 13 : -13)))
}

const addPadding = (text: string): string => {
  const bytesOverBlock = new Blob([text]).size % BLOCK_SIZE
  if (bytesOverBlock === 0) {
    return text
  }

  return text + randomString(BLOCK_SIZE - bytesOverBlock)
}

export const encode = (text: string): string => {
  text = randomString(randomNumber(6, 12)) + '+' + text + '-' + randomString(randomNumber(6, 10))
  return rot13(window.btoa(addPadding(text)))
}

export const decode = (text: string): string => {
  text = window.atob(rot13(text))
  return text.replace(/^[a-zA-Z0-9]+\+(.*)\-[a-zA-Z0-9]+$/, '$1')
}

export const isObfuscationEnabled = (): boolean => {
  return typeof Storage === undefined || localStorage.getItem('disable_api_obfuscation') !== '1'
}
