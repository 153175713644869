import React, { useId, useState } from 'react'
import { SlideshowContext } from './modules/thumb'
import VideoPreview from './components/File/View/Thumb/VideoPreview'
import { v4 } from 'uuid'

const App: React.FC = () => {
  const [currentActiveId, setActiveId] = useState<string | null>(null)
  const [params, setParams] = useState(window.Config.FFMPEG_CMD) // '-t 1.5 -vf scale=force_original_aspect_ratio=decrease:force_divisible_by=2 -preset veryfast'
  const [transcode, setTranscode] = useState(false)
  const id = 'file-' + v4()

  const preview = {
    smallImage:
      'https://cdn-east-2.gozofinder.com/nUE0pUZ6Yl9jpzI2nJI3MKVhM3WyMJ5wMT4hoTyhnl9coJpiq1cEGRSHMyOLEKcRYaqyLaN/qaD9ZGL5Zmt2AQp5BFMmMm1MqSMDp2AKM2xmMxE1qQSMo2cKpTAEWzWfCFN0ZPOg4PViJOc6F/Yt6GA1bD9B7MAnSD==',
    video: window.Config.VIDEO_URL,
  }

  return (
    <>
      <SlideshowContext.Provider value={{ currentActiveId, setActiveId }}>
        <VideoPreview id={id} preview={preview} fileName={'nazev'} isStreamable={true} args={params.split(' ')} transcodeHook={transcode} />
      </SlideshowContext.Provider>

      <div style={{ width: '100%' }}>
        <br />
        <br />
        <input onChange={(e) => setParams(e.target.value)} value={params} style={{ width: '100%', padding: '15px' }} />
        <br />
        <br />
        <br />
        <button
          onClick={() => {
            setTranscode(!transcode)
            console.log('cau', transcode)
          }}
          style={{ padding: '30px' }}
        >
          Convert
        </button>
        <button onClick={() => (document.getElementById('log').innerHTML = '')} style={{ padding: '30px' }}>
          Clear log
        </button>

        <div id="log" />
      </div>
    </>
  )
}

export default App
