{
  "components": {
    "ContactInquiryForm": {
      "contact": {
        "content": "How can we help?",
        "subject": "GOZO Finder contact"
      },
      "order": {
        "content": "Inquiry",
        "subject": "GOZO Finder enquiry"
      }
    },
    "ErrorBoundaryScreen": {
      "title": "Unfortunately, there was an unexpected error..."
    },
    "Languages": {
      "cz": "Česky",
      "sk": "Slovensky",
      "en": "English",
      "pl": "Polski"
    },
    "Theme": {
      "light": "Turn on dark mode",
      "dark": "Turn on light mode"
    },
    "Home": {
      "catchWord": "A fast, anonymous, and reliable search engine"
    },
    "Menu": {
      "settings": "Settings",
      "language": "Preferred language",
      "details": "Details",
      "about": "About",
      "products": "Products",
      "pricing": "Pricing",
      "privacy": "Privacy policy",
      "terms": "Terms of service",
      "contact": "Contact",
      "help": "Help"
    },
    "Advert": {
      "text": "Web advert",
      "link": "Visit",
      "close": "Close advert"
    },
    "BannerPorn": {
      "claim": "Looking for adults-only content?",
      "try": "Try",
      "button": "GOZO for {{name}}"
    },
    "File": {
      "FileListEmpty": {
        "empty": "No result found for query <1>\"<0/>\"</1>.",
        "hint": "Make sure that all words have been typed correctly.<br>Try search for similar words.<br>Simplify your query.",
        "emptyWithFilter": {
          "text": "Do you want to expand the search to <1><0/></1>?",
          "link": "all files"
        }
      },
      "FileListEnd": {
        "empty": "No more search results found for query <1>\"<0/>\"</1>."
      }
    },
    "Modal": {
      "RateLimiting": {
        "title": "Too Many Requests",
        "boxes": {
          "browser": {
            "title": "Browser",
            "status": "Working"
          },
          "cloudflare": {
            "title": "CloudFlare",
            "status": "Working",
            "imNotRobot": "I'm not a robot"
          },
          "gozo": {
            "title": "GOZO Finder",
            "status": "Error"
          }
        },
        "captcha": {
          "error": "User authentication failed, please try again.",
          "success": "User authentication succeeded, please wait.",
          "title": "User verification",
          "waiting": "Too many requests from IP address. Are you not a bot?"
        },
        "whatHappened": {
          "title": "What happened?",
          "body": "Security system evaluated abnormal amount of requests from your location and to continue using the service you have to prove that you are not a robot."
        }
      }
    },
    "Search": {
      "FilterBox": {
        "all": "All",
        "type": {
          "all": "All",
          "video": "Video",
          "image": "Images",
          "archive": "Archives",
          "audio": "Audio",
          "document": "Documents"
        },
        "typeBox": {
          "other": "Other",
          "filter": "Filter"
        },
        "sort": {
          "label": "Sort by",
          "values": {
            "relevance": "Relevance",
            "latest": "Latest first",
            "rating": "Rating",
            "largest": "Largest first",
            "smallest": "Smallest first"
          }
        },
        "video": {
          "duration": {
            "label": "Video length",
            "values": {
              "longest": "Long (more than 60 min.)",
              "long": "Longer (20-60 min.)",
              "middle": "Medium (5-20 min.)",
              "short": "Short (less than 5 min.)"
            }
          },
          "quality": {
            "label": "Video quality",
            "values": {
              "fullhd": "1080p or higher",
              "hd": "720p or higher",
              "lq": "Lower than 720p"
            }
          },
          "streaming": {
            "label": "Video type",
            "values": {
              "available": "Streaming",
              "unavailable": "Download"
            }
          },
          "size": {
            "label": "Size",
            "values": {
              "small": "Small",
              "medium": "Medium",
              "large": "Large"
            }
          }
        },
        "image": {
          "quality": {
            "label": "Quality",
            "values": {
              "hq": "High",
              "mq": "Medium",
              "lq": "Low"
            }
          },
          "size": {
            "label": "Size",
            "values": {
              "small": "Small",
              "medium": "Medium",
              "large": "Large"
            }
          }
        },
        "audio": {
          "quality": {
            "label": "Quality",
            "values": {
              "hq": "High",
              "mq": "Medium",
              "lq": "Low"
            }
          },
          "duration": {
            "label": "Duration",
            "values": {
              "short": "Short (less than 5 min.)",
              "middle": "Medium (5-20 min.)",
              "long": "Long (more than 20 min.)"
            }
          },
          "size": {
            "label": "Size",
            "values": {
              "small": "Small",
              "medium": "Medium",
              "large": "Large"
            }
          }
        },
        "archive": {
          "size": {
            "label": "Size",
            "values": {
              "small": "Small",
              "medium": "Medium",
              "large": "Large"
            }
          }
        },
        "document": {
          "size": {
            "label": "Size",
            "values": {
              "small": "Small",
              "medium": "Medium",
              "large": "Large"
            }
          }
        }
      },
      "QueryBox": {
        "placeholder": "Search",
        "placeholderUlozto": "Find files on Ulož.to Disk",
        "placeholderPinkfile": "Find files on PINKfile.cz"
      }
    }
  },
  "routes": {
    "Index": {
      "unsupportedBrowser": "Your browser is too old and may not work properly, we recommend updating.",
      "FileList": {
        "poweredBy": "Powered by GozoFinder"
      }
    },
    "UrlNotFound": {
      "title": "Page not found"
    },
    "FileNotFound": {
      "title": "File not found"
    }
  }
}
