import React, { Suspense, useLayoutEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ThemeProvider } from 'styled-components'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ErrorBoundaryScreen from './components/ErrorBoundaryScreen'
import LayoutService from './components/Layout/Service'
import LayoutHeader from './components/Layout/Header'
import Index from './routes/Index'
import PornfileRedirect from './components/PornfileRedirect'
import FileNotFound from './routes/FileNotFound'
import UrlNotFound from './routes/UrlNotFound'
import i18next from './modules/language/i18next'
import GlobalTheme from './constants/GlobalTheme'
import { GlobalStyles } from './constants/GlobalStyle'
import Loader from './components/Loader'
import RateLimiting from './routes/RateLimiting'
import { StaticRoutes } from './routes/Static/StaticRoutes'
import { isDevelopment } from './modules/config/application_mode'
import { AdvertPosition } from './modules/advert/model'
import AdvertStore from './modules/advert/store'
import { hasVisibilityCounter } from './modules/advert/visibilityCounter'
import { Country } from './modules/language/model'
import { Service } from './modules/config/model'
import { ConfigContextProvider } from './modules/config/context'
import { FFmpegProvider } from './modules/thumb/ffmpeg.context'
import VideoPreview from './components/File/View/Thumb/VideoPreview'
import { SlideshowContext } from './modules/thumb'
import Playground from './Playground'

if (isDevelopment()) {
  window.Config = {
    BACKEND_API_URL: process.env.BACKEND_API_URL,
    SENTRY_DSN_API: process.env.SENTRY_DSN_API,
    ENVIRONMENT: process.env.ENVIRONMENT,
    RECAPTCHA_SITE_KEY: window.Cypress ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' : process.env.RECAPTCHA_SITE_KEY,
    SEARCH_SUGGEST_SERVICES: process.env.SEARCH_SUGGEST_SERVICES.split(','),
    CAPTCHA_PROVIDERS_PRIORITY: process.env.CAPTCHA_PROVIDERS_PRIORITY,
    TURNSTILE_SITE_KEY: process.env.TURNSTILE_SITE_KEY,
    PREFER_PREVIEW: process.env.PREFER_PREVIEW === 'true',
  }
}

Sentry.init({
  debug: isDevelopment(),
  dsn: window.Config.SENTRY_DSN_API,
  release: process.env.VERSION,
  environment: window.Config.ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()],
  autoSessionTracking: false,
  tracesSampleRate: 0.001,
  normalizeDepth: 8,
  ignoreErrors: [
    // #415704
    'ibFindAllVideos',
    'vc_request_action',
    'BetterJsPop',
    'originalPrompt',
    'translate_fp',
    'MyAppGetAllDatas',
    'doJDSubmit',
    // recaptcha errors - #416360
    "Cannot read properties of null (reading 'style')",
    'w is null',
    "Cannot read property 'style' of null",
    'O is null',
    'UnhandledRejection: Non-Error promise rejection captured with value: Timeout',
  ],
  beforeSend(event) {
    // ignore network errors from google bot - #416360
    if (
      event &&
      event.message &&
      event.message.match(/network error/i) &&
      event.request?.headers &&
      event.request?.headers['User-Agent']?.includes('Googlebot')
    ) {
      return null
    }

    return event
  },
})

const CommonRoutes = (
  <>
    <Route index element={<Index />} />
    <Route path="file-not-found" element={<FileNotFound />} />
    <Route path="rate-limiting" element={<RateLimiting />} />
    <Route path="*" element={<UrlNotFound />} />
  </>
)

const App: React.FC = () => {
  useLayoutEffect(() => {
    Object.values(AdvertPosition).forEach((position) => AdvertStore.setVisibility(position, !hasVisibilityCounter(position)))
  }, [])

  return (
    <ThemeProvider theme={GlobalTheme}>
      <GlobalStyles />
      <Sentry.ErrorBoundary fallback={() => <ErrorBoundaryScreen />}>
        <I18nextProvider i18n={i18next}>
          <Suspense fallback={<Loader />}>
            <ConfigContextProvider>
              <FFmpegProvider>
                <Playground />
              </FFmpegProvider>
            </ConfigContextProvider>
          </Suspense>
        </I18nextProvider>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
