import React from 'react'
import { AxiosError, isCancel } from 'axios'

// https://github.com/bvaughn/react-error-boundary
const useErrorHandler = (givenError?: unknown): ((error: unknown) => void) => {
  const [error, setError] = React.useState<unknown>(null)
  if (givenError != null) throw givenError
  if (error != null) throw error
  return (err: unknown) => {
    if (
      isCancel(err) ||
      (typeof err === 'object' && err !== null && 'code' in err && (err as AxiosError).code === AxiosError.ECONNABORTED)
    ) {
      return
    }

    setError(err)
  }
}

export default useErrorHandler
