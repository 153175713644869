import React, { CSSProperties, forwardRef, useContext, useEffect, useId, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/browser'
import { AdvertPosition, AdvertSize } from '../../modules/advert/model'
import { getAdvertSize, isAdvertVisible } from '../../modules/advert/visibility'
import { ConfigContext } from '../../modules/config/context'
import ulozto_iframe380x380 from '../../public/banners/ulozto/ad380x380.html'
import ulozto_iframe500x200 from '../../public/banners/ulozto/ad500x200.html'
import ulozto_iframe990x280 from '../../public/banners/ulozto/ad990x280.html'
import ulozto_iframe1920x1500 from '../../public/banners/ulozto/ad1920x1500.html'
import pinkfile_iframe380x380 from '../../public/banners/pinkfile/ad380x380.html'
import pinkfile_iframe500x200 from '../../public/banners/pinkfile/ad500x200.html'
import pinkfile_iframe990x280 from '../../public/banners/pinkfile/ad990x280.html'
import pinkfile_iframe1920x1500 from '../../public/banners/pinkfile/ad1920x1500.html'
import { usePostMessage } from '../../hooks/usePostMessage'
import { getTheme, Theme } from '../../modules/config/theme'
import { Service } from '../../modules/config/model'
import { setVisibilityCounter } from '../../modules/advert/visibilityCounter'
import AdvertStore from '../../modules/advert/store'
import { useStore } from '../../hooks/useStore'
import AppStore from '../../modules/app/store'
import { SentrySeverity } from '../../modules/sentry/model'
import { Banner } from './styled'

const iframes = {
  [Service.Ulozto]: {
    [AdvertSize.S380x380]: ulozto_iframe380x380,
    [AdvertSize.S500x200]: ulozto_iframe500x200,
    [AdvertSize.S990x280]: ulozto_iframe990x280,
    [AdvertSize.S1920x1500]: ulozto_iframe1920x1500,
  },
  [Service.Pinkfile]: {
    [AdvertSize.S380x380]: pinkfile_iframe380x380,
    [AdvertSize.S500x200]: pinkfile_iframe500x200,
    [AdvertSize.S990x280]: pinkfile_iframe990x280,
    [AdvertSize.S1920x1500]: pinkfile_iframe1920x1500,
  },
} as Record<Service, Record<AdvertSize, string>>

enum PostMessageEventType {
  AdvertSize = 'advertSize',
  AdvertClick = 'advertClick',
  AdvertSetVisibility = 'advertSetVisibility',
}

interface ActualAdvertSize {
  width: number
  height: number
}

export interface IAdvertSetVisibility {
  visible: boolean
}

const getAdvertUrl = (service: Service, size: AdvertSize): string => iframes[service][size]

const constructIframeUrl = (urlString: string, sourceId: string, isDynamic: boolean, isCloseable: boolean, service: Service): URL => {
  const theme = getTheme(service)
  const url = new URL(urlString)

  url.searchParams.append('sourceId', sourceId)
  url.searchParams.append('theme', theme === Theme.Light ? Theme.Light : Theme.Dark)
  url.searchParams.append('background', getComputedStyle(document.getElementsByTagName('body')[0]).getPropertyValue('--c-bg'))
  url.searchParams.append('dynamic', isDynamic ? 'true' : 'false')
  url.searchParams.append('closeable', isCloseable ? 'true' : 'false')

  return url
}

const actualSizeToCSS = (size: ActualAdvertSize): CSSProperties => {
  return {
    width: size.width + 'px',
    height: size.height + 'px',
  }
}

interface Props {
  position: AdvertPosition
  isRolling?: boolean
  isDynamic?: boolean
  isCloseable?: boolean
  style?: CSSProperties
  iframeStyle?: CSSProperties
  ref?: React.ForwardedRef<HTMLDivElement>
}

const Advert: React.FC<Props> = forwardRef<HTMLDivElement, Props>(({ position, isDynamic, isCloseable, style, iframeStyle }, ref) => {
  const { t } = useTranslation()
  const advertStore = useStore(AdvertStore)
  const config = useContext(ConfigContext)
  const appStore = useStore(AppStore)
  const size = getAdvertSize(position)
  const sourceId = 'advert-' + useId()
  const message = usePostMessage(sourceId)
  const iframeContainerRef = useRef<HTMLDivElement | null>(null)
  const [actualAdvertSize, setActualAdvertSize] = useState<ActualAdvertSize | null>(null)

  const hideAdvert = () => {
    setVisibilityCounter(position)
    AdvertStore.setVisibility(position, false)
  }

  useEffect(() => {
    if (message && Object.values(PostMessageEventType).includes(message.type as PostMessageEventType)) {
      switch (message.type) {
        case PostMessageEventType.AdvertSize:
          setActualAdvertSize(message.data as ActualAdvertSize)
          break

        case PostMessageEventType.AdvertClick:
          setTimeout(hideAdvert, 500)
          break

        case PostMessageEventType.AdvertSetVisibility: {
          const data = message.data as IAdvertSetVisibility
          if (!data.visible) AdvertStore.setVisibility(position, false)
          break
        }

        default:
          Sentry.captureMessage('Unknown advert post message event type', {
            level: SentrySeverity.Warning,
            extra: { message },
          })
      }
    }
  }, [message])

  if (!isAdvertVisible(position, config, advertStore)) {
    return null
  }

  const iframeUrl = constructIframeUrl(getAdvertUrl(config.service, size), sourceId, !!isDynamic, !!isCloseable, config.service)

  return (
    <Banner
      ref={ref}
      className={'type-' + size}
      style={{ ...style }}
      $compensationWidth={appStore.scrollBarCompensation}
      data-testid="advert"
    >
      <div className="advert-message">{t('components.Advert.text')}</div>
      <div className="advert-iframe" ref={iframeContainerRef}>
        <iframe
          src={iframeUrl.toString()}
          style={{ ...iframeStyle, ...(actualAdvertSize ? actualSizeToCSS(actualAdvertSize) : {}) }}
          loading="lazy"
          width="100%"
        />
      </div>
      {isCloseable && (
        <div className={'buttons'}>
          <span>{t('components.Advert.link')}</span>
          <span onClick={hideAdvert} className={'close'}>
            {t('components.Advert.close')}
          </span>
        </div>
      )}
    </Banner>
  )
})

export default Advert
