import styled from 'styled-components'

export const Banner = styled.div<{
  $compensationWidth: number
}>`
  > .advert-message {
    display: inline-block;
    padding: 2px 5px;
    background-color: ${({ theme }) => theme.color.bg.shade5};
    color: ${({ theme }) => theme.color.text};
    font-size: 10px;
    pointer-events: none;
  }
  .advert-iframe {
    overflow: hidden;
  }
  iframe {
    border: 0;
    background-color: ${({ theme }) => theme.color.bg.shade5};
  }

  &.type-380x380 {
    height: 415px;

    iframe {
      height: 380px;
    }
  }
  &.type-500x200 {
    position: fixed;
    z-index: 20;
    bottom: 5px;
    left: 5px;
    display: block;
    width: calc(100% - 10px);

    iframe {
      box-shadow: 0 0 10px ${({ theme }) => theme.color.bg.shade50};
    }
  }
  &.type-990x280 {
    height: 315px;

    iframe {
      height: 280px;
    }
  }
  &.type-1920x1500 {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    width: ${({ $compensationWidth }) => ($compensationWidth > 0 ? 'calc(100% - ' + $compensationWidth + 'px)' : 100 + '%')};
    height: 100%;

    > .advert-message {
      position: absolute;
      max-width: 1030px;

      @media screen and (min-width: ${({ theme }) => theme.layout.sizes.xl}px) {
        left: 50%;
        margin-left: -515px;
      }
    }
    iframe {
      height: 1500px;
    }
  }

  .buttons {
    position: absolute;
    z-index: 20;
    bottom: 4px;
    left: 0;
    display: flex;
    width: 100%;
    padding: 0;
    font-size: 13px;
    gap: 1px;
    pointer-events: none;

    span {
      display: block;
      flex: 1;
      padding: 10px;
      background-color: ${({ theme }) => theme.color.link};
      color: ${({ theme }) => theme.color.white};
      text-align: center;

      &.close {
        background-color: ${({ theme }) => theme.color.white};
        color: ${({ theme }) => theme.color.black};
        cursor: pointer;
        pointer-events: auto;
      }
    }
  }
`
