import React, { useContext } from 'react'
import { ConfigContext } from '../../modules/config/context'
import { Service } from '../../modules/config/model'
import { LogoSvg } from './header/Logo'
import { LogoHomeSvg } from './header/LogoHome'
import { LogoSymbolSvg } from './header/LogoSymbol'
import { LogoPornSvg } from './header/LogoPorn'
import { LogoPornHomeSvg } from './header/LogoPornHome'
import { LogoPornSymbolSvg } from './header/LogoPornSymbol'

const decide = (UloztoComponent: React.FC, PinkfileComponent: React.FC): (() => JSX.Element) => {
  return (): JSX.Element => {
    const config = useContext(ConfigContext)

    if (config.service === Service.Pinkfile) {
      return <PinkfileComponent />
    } else {
      return <UloztoComponent />
    }
  }
}

const Images = {
  Logo: decide(LogoSvg, LogoPornSvg),
  LogoHome: decide(LogoHomeSvg, LogoPornHomeSvg),
  LogoSymbol: decide(LogoSymbolSvg, LogoPornSymbolSvg),
}

export { Images }
