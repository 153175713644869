import React from 'react'
import { ContentType, File, StreamingType } from '../../../../modules/file/model'
import Icon from '../../../Icons'

export const formatFileSize = (fileSize: number): string | null => {
  if (fileSize < 0) {
    return null
  }

  if (fileSize / 1000000000000 > 1) {
    return toDecimal(fileSize / 1000000000000) + ' TB'
  } else if (fileSize / 1000000000 > 1) {
    return toDecimal(fileSize / 1000000000) + ' GB'
  } else if (fileSize / 1000000 > 1) {
    return Math.round(fileSize / 1000000) + ' MB'
  } else if (fileSize / 1000 > 1) {
    return Math.round(fileSize / 1000) + ' kB'
  } else {
    return fileSize + ' B'
  }
}

const toDecimal = (num: number): number => {
  return Number(num.toFixed(1))
}

export const formatRating = (fileRating: number): string => {
  if (fileRating > 0) {
    return '+' + fileRating
  } else {
    return fileRating.toString()
  }
}

export const formatDuration = (seconds: number): string => {
  const roundedSecond = Math.round(seconds / 60) * 60
  const hours = Math.floor(roundedSecond / 3600)
  const minutes = Math.floor((roundedSecond % 3600) / 60)

  if (roundedSecond < 3600) {
    return Math.max(1, minutes) + 'min'
  }

  return hours + 'h' + (minutes ? ' ' + minutes + 'min' : '')
}

const videoQuality = (file: File): string | null => {
  if (!file.format || !file.format.width || !file.format.height) {
    return null
  }

  const area = file.format.width * file.format.height
  if (area > 5529600) {
    return '4K'
  } else if (area > 1440000) {
    return 'FullHD'
  } else if (area > 880440) {
    return 'HD'
  } else {
    return 'LQ'
  }
}

const hasThumbnail = (file: File) => file.thumbnail && file.thumbnail.images.length > 0

export const getFileIcons = (
  file: File
): {
  icon: React.FC
  text: string
}[] => {
  switch (file.hash?.contentType) {
    case ContentType.Video:
      const icons = []
      const streamingTypes = file.format?.streamingTypes || []

      const text = []
      const quality = videoQuality(file)
      if (quality) {
        text.push(quality)
      }
      if (streamingTypes.includes(StreamingType.Free)) {
        text.push('FREE')
      }

      icons.push({
        icon: streamingTypes.length ? Icon.Play : Icon.TypeVideo,
        text: text.join('|'),
      })

      if (file.format?.duration) {
        icons.push({ icon: Icon.Duration, text: formatDuration(file.format.duration) })
      }

      return icons
    case ContentType.Audio:
      return file.format?.duration ? [{ icon: Icon.Duration, text: formatDuration(file.format.duration) }] : []
    case ContentType.Image:
      return file.format?.width && file.format?.height
        ? [{ icon: Icon.TypeImage, text: file.format.width + ' × ' + file.format.height }]
        : []
    case ContentType.Archive:
      return hasThumbnail(file) ? [{ icon: Icon.TypeArchive, text: file.extension }] : []
    case ContentType.Document:
      return hasThumbnail(file) ? [{ icon: Icon.TypeDocument, text: file.extension }] : []
    default:
      return []
  }
}
