import React, { useState } from 'react'

interface CheckboxProps {
  label: string
  defaultChecked?: boolean
  onChange?: (isChecked: boolean) => void
}

export const Checkbox: React.FC<CheckboxProps> = ({ label, defaultChecked = false, onChange }) => {
  const [isChecked, setIsChecked] = useState(defaultChecked)

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    setIsChecked(checked)
    onChange?.(checked)
  }

  return (
    <label>
      <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
      {label}
    </label>
  )
}
