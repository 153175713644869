import React, { RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useWindowVirtualizer } from '@tanstack/react-virtual'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { isCancel } from 'axios'
import ListEmpty from '../../../components/File/ListEmpty'
import { File, View } from '../../../modules/file/model'
import { ErrorCodes as FileErrorCodes, getFileList, GetFileListResponse } from '../../../modules/file/api'
import useErrorHandler from '../../../hooks/useErrorHandler'
import theme from '../../../constants/GlobalTheme'
import useWindowSize from '../../../hooks/useWindowSize'
import { Service } from '../../../modules/config/model'
import BannerPorn from '../../../components/BannerPorn'
import { ConfigContext } from '../../../modules/config/context'
import UserContext from '../../../modules/user/context'
import { ApiRequestPromise } from '../../../modules/api/request'
import ModalRateLimiting from '../../../components/Modal/RateLimiting'
import { Advert, AdvertPosition } from '../../../modules/advert/model'
import { isDevelopment } from '../../../modules/config/application_mode'
import { decreaseVisibilityCounter } from '../../../modules/advert/visibilityCounter'
import { useStore } from '../../../hooks/useStore'
import AppStore from '../../../modules/app/store'
import SearchStore from '../../../modules/search/store'
import AdvertStore from '../../../modules/advert/store'
import { isAdvertVisible } from '../../../modules/advert/visibility'
import { isApiError } from '../../../modules/api/error'
import { ErrorCodes as RateLimitingErrorCodes } from '../../../modules/rateLimiting/api'
import { List, Props as ListProps } from '../../../components/File/List'
import ListEnd from '../../../components/File/ListEnd'
import { setRateLimitedAt } from '../../../components/Modal/RateLimiting/recaptcha'
import { isMgService } from '../../../modules/config/service'
import { isBot } from '../../../utils/browser.utils'
import { ListWrapper, Loading, Powered } from './styled'
import { decideAdverts, decideView, insertAdverts, insertFiles, removeAdverts } from './list'

const PAGE_SIZE = 30
const GRID_OVERSCAN = 3
export const MAX_FILES = 1000

interface RateLimitingState {
  isRateLimited: boolean
  callback?: () => void
}

interface FileListState {
  view: View // we need to change the view >after< loading files from API
  items: (File | Advert)[]
  filesCount: number
  isSmutQuery: boolean
  isInitialized: boolean
  isFullyLoaded: boolean
  advertsHook: boolean
}

interface GridProps {
  itemWidth: number
  gridGap: number
  sideMargin: number
  columns: number
  isFullyLoaded: boolean
}

interface Props {
  containerRef: RefObject<HTMLDivElement>
}

const FileListRoute: React.FC<Props> = ({ containerRef }) => {
  const { t } = useTranslation()
  const config = useContext(ConfigContext)
  const appStore = useStore(AppStore)
  const user = useContext(UserContext)
  const errHandler = useErrorHandler()
  const windowSize = useWindowSize()
  const searchStore = useStore(SearchStore)
  const advertStore = useStore(AdvertStore)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [rateLimitingState, setRateLimitingState] = useState<RateLimitingState>({ isRateLimited: false })
  const [fileListState, setFileListState] = useState<FileListState>({
    view: View.List,
    items: [],
    filesCount: 0,
    isSmutQuery: false,
    isInitialized: false,
    isFullyLoaded: false,
    advertsHook: false,
  })
  const fileListRequest = useRef<ApiRequestPromise<GetFileListResponse> | undefined>(undefined)
  const setFileListRequest = (request: ApiRequestPromise<GetFileListResponse> | undefined) => (fileListRequest.current = request)

  /**
   * ******************** REACT VIRTUAL ********************
   */

  const gridProps: GridProps = {
    itemWidth: windowSize.width >= theme.layout.sizes.sm ? 220 : 155,
    gridGap: windowSize.width >= theme.layout.sizes.sm ? 10 : 15,
    sideMargin: windowSize.width >= theme.layout.sizes.lg ? 20 : 10,
    columns: 0,
    isFullyLoaded: fileListState.isFullyLoaded,
  }
  gridProps.columns = Math.floor(
    ((containerRef.current?.offsetWidth || windowSize.width) - gridProps.sideMargin * 2) / (gridProps.itemWidth + gridProps.gridGap)
  )

  // let's load more files than usually for first request when there aren't any files in list
  const initialRowsCount = fileListState.view === View.List ? PAGE_SIZE * 2 : Math.ceil((PAGE_SIZE * 3) / gridProps.columns)
  const getRowsCount = useCallback(() => {
    let rowsCount: number
    if (fileListState.isInitialized && fileListState.filesCount) {
      const gridRows = Math.ceil(fileListState.filesCount / gridProps.columns)
      rowsCount =
        fileListState.view === View.List
          ? Math.min(fileListState.isFullyLoaded ? fileListState.filesCount : fileListState.filesCount + PAGE_SIZE, MAX_FILES)
          : Math.min(fileListState.isFullyLoaded ? gridRows : gridRows + GRID_OVERSCAN, Math.ceil(MAX_FILES / gridProps.columns))
    } else {
      rowsCount = initialRowsCount
    }
    return rowsCount
  }, [
    fileListState.filesCount,
    fileListState.isFullyLoaded,
    fileListState.isInitialized,
    fileListState.view,
    gridProps.columns,
    initialRowsCount,
  ])

  // const estimateSize = () => (fileListState.view === View.Grid ? gridProps.itemWidth : windowSize.width > theme.layout.sizes.md ? 140 : 120)
  const getEstimateSize = useCallback(
    () => () => fileListState.view === View.Grid ? gridProps.itemWidth : windowSize.width > theme.layout.sizes.md ? 140 : 120,
    [fileListState.view, gridProps.itemWidth, windowSize.width]
  )

  const getOverscan = useCallback(() => (fileListState.view === View.List ? PAGE_SIZE : GRID_OVERSCAN), [fileListState.view])

  const virtualizer = useWindowVirtualizer({
    estimateSize: getEstimateSize(),
    debug: isDevelopment(),
    count: getRowsCount(),
    overscan: getOverscan(),
  })

  /**
   * ******************** FUNCTIONS ********************
   */

  const createFileListErrHandler = (rateLimitingCallback: () => void): ((error: Error) => void) => {
    return (error: Error) => {
      setFileListRequest(undefined)

      if (isCancel(error)) {
        return
      } else if (isApiError(error, FileErrorCodes.InvalidSearchQuery)) {
        setFileListState((prevState) => ({
          ...prevState,
          items: [],
          filesCount: 0,
          isSmutQuery: false,
          isInitialized: true,
          isFullyLoaded: true,
        }))
        window.scrollTo(0, 0)
        virtualizer.scrollToOffset(0)
        setIsLoading(false)
        decreaseVisibilityCounter()

        return
      } else if (isApiError(error, RateLimitingErrorCodes.TooManyRequests)) {
        setRateLimitedAt()
        setRateLimitingState({
          isRateLimited: true,
          callback: rateLimitingCallback,
        })
        return
      }

      errHandler(error)
    }
  }

  const handleFiltersChange = (): void => {
    if (!encodeURIComponent(searchStore.searchQuery)) {
      return
    }

    const disallowSearch = isMgService(config.service) && isBot()
    if (disallowSearch) {
      setIsLoading(false)
      return
    }

    fileListRequest && fileListRequest.current?.cancel()

    setIsLoading(true)

    const limit = Math.min(initialRowsCount, 200) // 200 is API limit
    const request = getFileList(user.sessionId, config.service, searchStore.searchQuery, searchStore.searchFilter, limit, 0)
    setFileListRequest(request)

    request
      .then((response) => {
        const { data } = response
        const allowAdverts = decideAdverts(config, response)
        const view = decideView(searchStore.searchFilter.contentType)
        const list = insertFiles([], 0, data.items)

        setFileListState((prevState) => ({
          view,
          items: list,
          filesCount: data.items.length,
          isSmutQuery: data.metadata.is_smut_query,
          isInitialized: true,
          isFullyLoaded: data.items.length === 0 || data.items.length < limit,
          advertsHook: !prevState.advertsHook,
        }))

        window.scrollTo(0, 0)
        virtualizer.scrollToOffset(0)
        setFileListRequest(undefined)
        setIsLoading(false)

        if (advertStore.areAllowed) {
          decreaseVisibilityCounter()
        }

        AdvertStore.setAreAllowed(allowAdverts)
      })
      .catch(createFileListErrHandler(handleFiltersChange))
  }

  const handleInfiniteScroll = (): void => {
    const disallowSearch = isMgService(config.service) && isBot()
    if (disallowSearch) {
      setIsLoading(false)
      return
    }

    let firstSkeletonIndex: number | undefined
    if (fileListState.view === View.List) {
      firstSkeletonIndex = virtualizer.getVirtualItems().find((item) => fileListState.items[item.index] === undefined)?.index
    } else {
      // in case of grid, we need to find index in a row which can consist of multiple files
      virtualizer.getVirtualItems().find((item) => {
        const start = item.index * gridProps.columns
        firstSkeletonIndex = _.range(start, start + gridProps.columns).find((index) => fileListState.items[index] === undefined)
        return !!firstSkeletonIndex
      })
    }

    if (fileListRequest.current || fileListState.isFullyLoaded || firstSkeletonIndex === undefined) {
      return
    }

    const offset = firstSkeletonIndex
    const limit = Math.min(
      Math.max(MAX_FILES - offset, 0),
      fileListState.view === View.List ? virtualizer.getVirtualItems().length : virtualizer.getVirtualItems().length * gridProps.columns,
      fileListState.view === View.List ? PAGE_SIZE : PAGE_SIZE * 2,
      200 // api limit
    )

    if (limit == 0) {
      setFileListState((prevState) => ({ ...prevState, isFullyLoaded: true }))
      return
    }

    const request = getFileList(user.sessionId, config.service, searchStore.searchQuery, searchStore.searchFilter, limit, offset)
    setFileListRequest(request)

    request
      .then((response) => {
        const { data } = response

        setFileListState((prevState) => ({
          ...prevState,
          view: decideView(searchStore.searchFilter.contentType),
          items: insertFiles(prevState.items, firstSkeletonIndex as number, data.items),
          filesCount: prevState.items.length + data.items.length,
          isSmutQuery: data.metadata.is_smut_query,
          isFullyLoaded: data.items.length === 0 || limit + offset >= MAX_FILES,
          advertsHook: !prevState.advertsHook,
        }))
        if (advertStore.areAllowed) {
          decreaseVisibilityCounter()
        }
        setFileListRequest(undefined)
      })
      .catch(createFileListErrHandler(handleInfiniteScroll))
  }

  /**
   * ******************** EFFECTS ********************
   */

  useEffect(handleFiltersChange, [searchStore.searchQuery, searchStore.searchFilter, searchStore.forceReload])
  useEffect(handleInfiniteScroll, [virtualizer.getVirtualItems(), fileListState.items]) // ensure no item skeletons
  useEffect(() => () => fileListRequest && fileListRequest.current?.cancel(), []) // cancel request on component unmount
  useEffect(() => {
    setFileListState((prevState) => {
      let list = removeAdverts([...prevState.items])

      if (isAdvertVisible(AdvertPosition.MiddleList, config, advertStore)) {
        list = insertAdverts(list, config.service, prevState.view, gridProps.columns)
      }

      return { ...prevState, items: list }
    })
  }, [containerRef.current?.offsetWidth, fileListState.advertsHook, advertStore.areAllowed, advertStore.adverts[AdvertPosition.MiddleList]])

  /**
   * ******************** RENDER ********************
   */

  const rateLimitingModal = (): false | JSX.Element => {
    return (
      rateLimitingState.isRateLimited && (
        <ModalRateLimiting
          callback={(): void => {
            if (rateLimitingState.callback === undefined) {
              throw new Error('Rate-limiting callback is undefined!')
            }
            rateLimitingState.callback()
            setRateLimitingState({ isRateLimited: false })
          }}
        />
      )
    )
  }

  // there can happen that fileListState is filled but virtual items are not, so display loading instead of file-list
  const isVirtualizationLoading = fileListState.filesCount > 0 && virtualizer.getVirtualItems().length == 0
  if (isLoading || isVirtualizationLoading) {
    return (
      <>
        {rateLimitingModal()}
        <Loading />
      </>
    )
  }

  const listProps: ListProps = {
    rowsCount: getRowsCount(),
    view: fileListState.view,
    items: fileListState.items,
    totalSize: virtualizer.getTotalSize(),
    virtualItems: virtualizer.getVirtualItems(),
    grid: gridProps,
    getListWrapperRef: (): HTMLDivElement | null => wrapperRef.current,
  }

  return (
    <ListWrapper data-testid={`fileList${fileListState.view === View.Grid ? ' fileList-grid' : ''}`} ref={wrapperRef}>
      {rateLimitingModal()}
      {fileListState.isSmutQuery && config.service === Service.Ulozto && <BannerPorn />}

      {fileListState.filesCount === 0 ? (
        <ListEmpty />
      ) : (
        <>
          <List {...listProps} />
          {fileListState.isFullyLoaded && <ListEnd searchQuery={searchStore.searchQuery} />}
        </>
      )}
      {(config.service === Service.Ulozto || config.service === Service.Pinkfile) && (
        <Powered $compensationWidth={appStore.scrollBarCompensation}>
          <span>{t('routes.Index.FileList.poweredBy')}</span>
        </Powered>
      )}
    </ListWrapper>
  )
}

export default FileListRoute
