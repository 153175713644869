import { ApiRequestPromise, ApiResponse, createRequestInit, request } from '../api/request'
import { Method } from '../api/model'
import { SessionId } from '../user/model'
import { Service } from '../config/model'

export const apiCreateLog = (
  sessionId: SessionId,
  service: Service,
  data: Record<string, unknown>,
  logName?: string
): ApiRequestPromise<ApiResponse> => {
  const requestData = {
    ...(logName && { name: logName }),
    service,
    sessionId,
    timestamp: new Date().toISOString(),
    browserInfo: {
      appName: navigator.appName || '',
      language: navigator.language || '',
      platform: navigator.platform || '',
      product: navigator.product || '',
      productSub: navigator.productSub || '',
      userAgent: navigator.userAgent || '',
      vendor: navigator.vendor || '',
      vendorSub: navigator.vendorSub || '',
    },
    data,
  }
  return request('v1/app-logger', sessionId, createRequestInit(Method.POST, { data: JSON.stringify(requestData) }))
}
