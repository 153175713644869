{
  "components": {
    "ErrorBoundaryScreen": {
      "title": "Bohužel, nastala neočekávaná chyba..."
    },
    "Languages": {
      "cz": "Česky",
      "sk": "Slovensky",
      "en": "English",
      "pl": "Polski"
    },
    "Theme": {
      "light": "Zapnout tmavý režim",
      "dark": "Zapnout světlý režim"
    },
    "Home": {
      "catchWord": "Rychlý, anonymní a spolehlivý vyhledávač"
    },
    "Menu": {
      "settings": "Nastavení",
      "language": "Jazyk",
      "details": "Podrobnosti",
      "about": "O nás",
      "products": "Produkty",
      "pricing": "Ceník",
      "privacy": "Zásady ochrany osobních údajů",
      "terms": "Podmínky služby",
      "contact": "Kontakt",
      "help": "Nápověda"
    },
    "Advert": {
      "text": "Reklamní sdělení",
      "link": "Navštívit",
      "close": "Zavřít reklamu"
    },
    "BannerPorn": {
      "claim": "Hledáte obsah pouze pro dospělé?",
      "try": "Zkuste",
      "button": "GOZO pro {{name}}"
    },
    "File": {
      "FileListEmpty": {
        "empty": "Na vyhledávací dotaz <1>\"<0/>\"</1> nebylo nic nalezeno.",
        "hint": "Ujistěte se, že všechna slova jsou napsaná správně.<br>Zkuste vyhledat jiná podobná slova.<br>Zjednodušte váš dotaz.",
        "emptyWithFilter": {
          "text": "Chcete rozšířit hledání na <1><0/></1>?",
          "link": "všechny soubory"
        }
      },
      "FileListEnd": {
        "empty": "Na vyhledávací dotaz <1>\"<0/>\"</1> nebyly nalezeny žádné další výsledky."
      }
    },
    "Modal": {
      "RateLimiting": {
        "title": "Příliš mnoho požadavků",
        "boxes": {
          "browser": {
            "title": "Prohlížeč",
            "status": "V pořádku"
          },
          "cloudflare": {
            "title": "CloudFlare",
            "status": "V pořádku",
            "imNotRobot": "Nejsem robot"
          },
          "gozo": {
            "title": "GOZO Finder",
            "status": "Chyba"
          }
        },
        "captcha": {
          "error": "Ověření uživatele se nepovedlo, zkuste to prosím znovu.",
          "success": "Ověření uživatele proběhlo úspěšně, počkejte prosím.",
          "title": "Ověření uživatele",
          "waiting": "Příliš mnoho požadavků z IP adresy. Nejste náhodou bot?"
        },
        "whatHappened": {
          "title": "Co se stalo?",
          "body": "Bezpečnostní systém vyhodnotil abnormální množství požadavků z vaší lokality a pro další užívání služby musíte prokázat, že nejste robot."
        }
      }
    },
    "Search": {
      "FilterBox": {
        "all": "Vše",
        "type": {
          "all": "Vše",
          "video": "Video",
          "image": "Obrázky",
          "archive": "Archivy",
          "audio": "Audio",
          "document": "Dokumenty"
        },
        "typeBox": {
          "other": "Další",
          "filter": "Filtr"
        },
        "sort": {
          "label": "Seřadit podle",
          "values": {
            "relevance": "Relevance",
            "latest": "Nejnovější",
            "rating": "Hodnocení",
            "largest": "Od největšího",
            "smallest": "Od nejmenšího"
          }
        },
        "video": {
          "duration": {
            "label": "Délka",
            "values": {
              "longest": "Dlouhé (nad 60 minut)",
              "long": "Delší (20-60 minut)",
              "middle": "Střední (5-20 minut)",
              "short": "Krátké (do 5 minut)"
            }
          },
          "quality": {
            "label": "Kvalita videa",
            "values": {
              "fullhd": "1080p nebo více",
              "hd": "720p nebo více",
              "lq": "Nižší než 720p"
            }
          },
          "streaming": {
            "label": "Typ videa",
            "values": {
              "available": "Streamování",
              "unavailable": "Stahování"
            }
          },
          "size": {
            "label": "Velikost",
            "values": {
              "small": "Malé",
              "medium": "Střední",
              "large": "Velké"
            }
          }
        },
        "image": {
          "quality": {
            "label": "Kvalita",
            "values": {
              "hq": "Vysoká",
              "mq": "Střední",
              "lq": "Nízká"
            }
          },
          "size": {
            "label": "Velikost",
            "values": {
              "small": "Malé",
              "medium": "Střední",
              "large": "Velké"
            }
          }
        },
        "audio": {
          "quality": {
            "label": "Kvalita",
            "values": {
              "hq": "Vysoká",
              "mq": "Střední",
              "lq": "Nízká"
            }
          },
          "duration": {
            "label": "Délka",
            "values": {
              "short": "Krátké (do 5 minut)",
              "middle": "Střední (5-20 minut)",
              "long": "Dlouhé (nad 20 minut)"
            }
          },
          "size": {
            "label": "Velikost",
            "values": {
              "small": "Malé",
              "medium": "Střední",
              "large": "Velké"
            }
          }
        },
        "archive": {
          "size": {
            "label": "Velikost",
            "values": {
              "small": "Malé",
              "medium": "Střední",
              "large": "Velké"
            }
          }
        },
        "document": {
          "size": {
            "label": "Velikost",
            "values": {
              "small": "Malé",
              "medium": "Střední",
              "large": "Velké"
            }
          }
        }
      },
      "QueryBox": {
        "placeholder": "Zadejte hledaný výraz",
        "placeholderUlozto": "Hledat soubory na Ulož.to Disk",
        "placeholderPinkfile": "Hledat soubory na PINKfile.cz"
      }
    }
  },
  "routes": {
    "Index": {
      "unsupportedBrowser": "Váš prohlížeč je příliš starý a nemusí fungovat správně, doporučujeme vám aktualizovat.",
      "FileList": {
        "poweredBy": "Powered by GozoFinder"
      }
    },
    "PornDisclaimer": {
      "warning": "Upozornění! Následující obsah je pouze pro dospělé!",
      "accept": "Vstoupit",
      "reject": "Odejít",
      "content": "Vstupem na následující stránky prohlašuji, že jsem dospělý ve věku 18 let nebo starší a dosáhl jsem zletilosti."
    },
    "UrlNotFound": {
      "title": "Stránka nenalezena"
    },
    "FileNotFound": {
      "title": "Soubor nenalezen"
    }
  }
}
