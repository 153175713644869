import { GetFileListResponse } from '../../../modules/file/api'
import { Advert, AdvertPosition } from '../../../modules/advert/model'
import { File, View } from '../../../modules/file/model'
import { Service } from '../../../modules/config/model'
import { ConfigContextValue } from '../../../modules/config/context'
import adverts from '../../../adverts.json'
import { SearchFilterType } from '../../../modules/search/model'
import { isAdvert } from '../../../components/File/View/helpers'
import { MAX_FILES } from './index'

export const decideView = (searchContentType: SearchFilterType): View =>
  [SearchFilterType.Image, SearchFilterType.Video].includes(searchContentType) ? View.Grid : View.List

export const decideAdverts = (config: ConfigContextValue, { data }: GetFileListResponse): boolean =>
  !data.metadata.is_smut_query || config.service === Service.Pinkfile

export const insertFiles = (list: (File | Advert)[], index: number, files: File[]): (File | Advert)[] => {
  list = [...list]
  files = [...files]

  let item = files.shift()
  while (item) {
    if (index >= MAX_FILES) {
      break
    }
    if (list[index] !== undefined) {
      index++
      continue
    }

    list[index] = item
    item = files.shift()
  }

  return list
}

export const insertAdverts = (list: (File | Advert)[], service: Service, view: View, gridColumns: number): (File | Advert)[] => {
  list = [...list]

  if (view === View.List) {
    adverts[service].middleList.forEach((advertPosition) => {
      list = insertAdvert(list, advertPosition)
    })
  } else {
    let inserted = 0
    adverts[service].middleList.forEach((advertPosition) => {
      // advert will be on whole row, so overflowing files will be shifted
      // we need to calculate with it
      const adIndex = advertPosition * gridColumns - inserted * (gridColumns - 1)
      list = insertAdvert(list, adIndex)
      inserted++
    })
  }

  return list
}

export const removeAdverts = (list: (File | Advert)[]): (File | Advert)[] => {
  return list.filter((item) => !isAdvert(item))
}

const insertAdvert = (items: (File | Advert)[], position: number): (File | Advert)[] => {
  const ad: Advert = { position: AdvertPosition.MiddleList }

  if (items[position] === undefined) {
    items[position] = ad
  } else {
    items.splice(position, 0, ad)
  }

  return items
}
