{
  "mg": {
    "enabled": false,
    "size": {
      "380x380": false,
      "500x200": false,
      "990x280": false,
      "1920x1500": false
    },
    "middleList": []
  },
  "ulozto": {
    "enabled": true,
    "size": {
      "380x380": true,
      "500x200": true,
      "990x280": true,
      "1920x1500": true
    },
    "middleList": [9, 49]
  },
  "pinkfile": {
    "enabled": true,
    "size": {
      "380x380": true,
      "500x200": true,
      "990x280": true,
      "1920x1500": true
    },
    "middleList": [9, 49]
  }
}
