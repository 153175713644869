import { ApiClientError } from './request'

export enum AxiosError {
  BAD_OPTION_VALUE = 'ERR_BAD_OPTION_VALUE',
  BAD_OPTION = 'ERR_BAD_OPTION',
  // browser request cancellation (as opposed to manual cancellation)
  ECONNABORTED = 'ECONNABORTED',
  // connection timed out
  ETIMEDOUT = 'ETIMEDOUT',
  // low level network errors - server unavailable, wrong host... (real errors are hidden by the browser)
  NETWORK = 'ERR_NETWORK',
  // redirect loop somewhere
  TOO_MANY_REDIRECTS = 'ERR_FR_TOO_MANY_REDIRECTS',
  DEPRECATED = 'ERR_DEPRECATED',
  BAD_RESPONSE = 'ERR_BAD_RESPONSE',
  // unsupported protocol
  BAD_REQUEST = 'ERR_BAD_REQUEST',
  // request cancelled by abort controller
  CANCELED = 'ERR_CANCELED',
  NOT_SUPPORT = 'ERR_NOT_SUPPORT',
  INVALID_URL = 'ERR_INVALID_URL',
}

export const isApiError = (error: Error, code: number): boolean => {
  return error instanceof ApiClientError && error.response.data.code === code
}
