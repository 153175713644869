import styled from 'styled-components'
import mixins from '../../../constants/GlobalStyleMixins'

export const Home = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.bg.shade5};

  a {
    display: block;
    margin-bottom: 20px;
  }
`

export const Logo = styled.div`
  a {
    position: relative;
    display: block;

    .stamp {
      position: absolute;
      right: 12px;
      bottom: 10px;
      display: inline-block;
      padding: 2px 5px;
      background-color: ${({ theme }) => theme.color.text};
      border-radius: 3px;
      color: ${({ theme }) => theme.color.bg.input};
      font-size: 10px;
      font-weight: 500;
      text-align: right;
    }
  }
  svg {
    ${mixins.logoHome}
    margin-bottom: 20px;
    color: ${({ theme }) => theme.color.text};
  }
`

export const CatchWord = styled.div`
  padding: 20px;
  text-align: center;
`
