import { Country } from '../language/model'
import { Service } from '../config/model'
import advertsConfig from '../../adverts.json'
import { AdvertPosition, AdvertSize } from './model'
import { AdvertStoreState } from './store'

export const isAdvertVisible = (
  position: AdvertPosition,
  config: { service: Service; country: Country },
  storeContext: AdvertStoreState
): boolean => {
  const size = getAdvertSize(position)

  if (!advertsConfig[config.service]['enabled'] || !advertsConfig[config.service]['size'][size]) {
    return false
  }

  if (position === AdvertPosition.Branding && window.innerWidth <= 500) {
    return false
  }

  if (position === AdvertPosition.BottomPopUp && window.innerWidth > 500) {
    return false
  }

  if (!storeContext.areAllowed || !storeContext.adverts[position]) {
    return false
  }

  return true
}

export const getAdvertSize = (position: AdvertPosition): AdvertSize => {
  switch (position) {
    case AdvertPosition.Branding:
      return AdvertSize.S1920x1500
    case AdvertPosition.BottomPopUp:
      return AdvertSize.S500x200
    case AdvertPosition.MiddleList:
      if (window.innerWidth > 990) {
        return AdvertSize.S990x280
      } else {
        return AdvertSize.S380x380
      }
    default:
      throw new Error('Unknown advert position ' + position)
  }
}
