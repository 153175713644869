import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Preview } from '../../../../modules/file/model'
import { SlideshowContext, FFmpegContext, ffmpegLog } from '../../../../modules/thumb'
import useSwipeActivated from '../../../../hooks/useSwipeActivated'
import { TNullable } from '../../../../types'
import Loader from '../../../Loader'
import Icon from '../../../Icons'
import { Image, ThumbWrapper } from './styled'
import {
  applyAttributes,
  createDefaultAttributes,
  shouldTranscode,
  videoPlay,
  videoStop,
  videoTranscode,
  videoReset,
  isNotFoundError,
} from './video'

interface Props {
  id: string
  preview: Preview
  fileName: string
  isStreamable: boolean
  args: string[]
  transcodeHook: boolean
}

const VideoPreview: React.FC<Props> = ({ id, preview, fileName, isStreamable, args, transcodeHook }) => {
  const { currentActiveId, setActiveId } = useContext(SlideshowContext)
  const ffmpegContext = useContext(FFmpegContext)
  const [isTranscoding, setIsTranscoding] = useState(false)
  const [isPosterDisplayed, setIsPosterDisplayed] = useState(true)
  const transcodeRetriesRef = useRef(0)
  const currentActiveIdRef = useRef<TNullable<string>>(null)
  const videoRef = useRef<TNullable<HTMLVideoElement>>(null)
  const defaultAttributes = useMemo(() => createDefaultAttributes(preview.smallImage || preview.largeImage), [preview])

  const start = useCallback(async () => {
    const video = videoRef.current
    setActiveId(id)
    currentActiveIdRef.current = id
    if (!video) return
    ffmpegContext.setLastVisitedVideo(id, preview.video as string, video)
    applyAttributes(video, defaultAttributes)

    if (shouldTranscode(video)) {
      ffmpegLog('start:: transcoding started: ', id)
      setIsTranscoding(true)
      const success = await videoTranscode(ffmpegContext, video, preview.video as string, id, args)
      ffmpegLog('start:: transcoding successful: ', !!success, id)
      setIsTranscoding(false)
      if (!success) return
    }

    // skip video play if it's no more active or is transcoding
    const isActive = currentActiveIdRef.current === id
    if (!isActive || (shouldTranscode(video) && isTranscoding)) return

    await videoPlay(video, id, preview.video as string, ffmpegContext)
  }, [videoRef, id, currentActiveId, ffmpegContext, args])

  // transcode last visited video in case ffmpeg was loaded after the video was visited
  const transcodeLastVisited = useCallback(async () => {
    const video = videoRef.current
    if (!video) return
    if (!shouldTranscode(video) || !ffmpegContext.ffmpeg?.loaded) return
    applyAttributes(video, defaultAttributes)
    ffmpegLog('transcodeLastVisited:: transcoding started: ', id)
    const success = await videoTranscode(ffmpegContext, video, preview.video as string, id, args)
    ffmpegLog('transcodeLastVisited:: transcoding successful: ', !!success, id)
    if (!success) return

    const isActive = currentActiveIdRef.current === id
    ffmpegLog('transcodeLastVisited:: is active: ', isActive)
    if (!isActive) return

    ffmpegLog('transcodeLastVisited:: playing video: ', id)
    await videoPlay(video, id, preview.video as string, ffmpegContext)
  }, [videoRef, id, currentActiveId, ffmpegContext, args])

  const touchEvents = useSwipeActivated(start)
  const showPoster = currentActiveId !== id && !!defaultAttributes.poster
  const videoSrc = ffmpegContext.getVideo(id) || preview.video

  useEffect(() => {
    // we need to pause video and hide transcoding loader after the poster is rendered
    // if not user will see a glitch (two different video screens)
    if (isPosterDisplayed && videoRef.current) {
      videoStop(videoRef.current)
      setIsTranscoding(false)
    }
  }, [isPosterDisplayed])

  useEffect(() => {
    // mark the video poster is not displayed before next Image.onLoad
    if (!showPoster) {
      setIsPosterDisplayed(false)
    }
  }, [showPoster])

  useEffect(() => {
    // in case video became inactive in middle of transcoding we are able to not play it
    currentActiveIdRef.current = currentActiveId
  }, [currentActiveId])

  useEffect(() => {
    // if user hovered any video before ffmpeg is fully loaded
    // we need to transcode it after ffmpeg loading is finished
    const isLastVisited = id === ffmpegContext.lastVisitedVideo?.id
    if (isLastVisited) transcodeLastVisited()
  }, [ffmpegContext.ffmpeg?.loaded, ffmpegContext.lastVisitedVideo])

  // reset errored transcoded video so it can be transcoded again
  const handleVideoError = () => {
    const video = videoRef.current
    if (!video) return
    // do not reset video for non-transcoded video
    if (isNotFoundError(video)) return
    const MAX_RETRIES = 2
    const shouldRetryTranscoding = video && preview.video && transcodeRetriesRef.current < MAX_RETRIES
    if (!shouldRetryTranscoding) return
    videoReset(video, id, preview.video as string, ffmpegContext)
    transcodeRetriesRef.current += 1
  }

  useEffect(() => {
    const video = videoRef.current
    if (!video || !preview.video) return
    video.addEventListener('error', handleVideoError)
    return () => video.removeEventListener('error', handleVideoError)
  }, [])

  useEffect(() => {
    console.log('hook')
    const video = videoRef.current
    if (!video || !preview.video) return
    videoReset(video, id, preview.video as string, ffmpegContext).then(start)
  }, [transcodeHook])

  return (
    <ThumbWrapper onMouseEnter={start} onMouseLeave={() => setActiveId(null)} {...touchEvents} className="wrapper">
      {isTranscoding && (
        <div className="loader">
          <Loader />
        </div>
      )}
      {/* we need own poster because the video tag can not display poster again after video was played */}
      {/*{showPoster && <Image src={defaultAttributes.poster} alt={fileName} onLoad={() => setIsPosterDisplayed(true)} loading="lazy" />}*/}
      {isStreamable && currentActiveId !== id && (
        <div className="play">
          <Icon.PlaySolid />
        </div>
      )}
      <video ref={videoRef} src={videoSrc} {...defaultAttributes} />
    </ThumbWrapper>
  )
}

export default VideoPreview
