import React from 'react'

const LogoPornSvg: React.FC = () => (
  <svg viewBox="0 0 330 100" width="1em" height="1em" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#f6a7b5"
      d="m147.7 74.2c-3.7 0-7.2-0.6-10.2-1.8-3.1-1.2-5.8-2.9-8.1-5.2-2.3-2.2-4.1-4.8-5.3-7.8-1.3-3-1.9-6.3-1.9-9.8s0.6-6.9 1.9-9.8c1.3-3 3.1-5.6 5.4-7.8s5-4 8.2-5.2c3.1-1.2 6.6-1.8 10.3-1.8 3.8 0 7.3 0.6 10.5 1.8 3.1 1.2 5.9 3.1 8.1 5.6l-4.3 4.3c-2-1.9-4.1-3.4-6.4-4.2-2.3-0.9-4.8-1.4-7.6-1.4-2.7 0-5.3 0.5-7.7 1.4s-4.4 2.2-6.1 3.9c-1.8 1.7-3.1 3.6-4 5.9s-1.4 4.7-1.4 7.4c0 2.6 0.5 5 1.4 7.3s2.3 4.3 4 6c1.8 1.7 3.7 3 6.1 3.9s4.9 1.4 7.6 1.4c2.6 0 5-0.4 7.4-1.2s4.6-2.1 6.6-4l3.9 5.3c-2.4 2-5.2 3.5-8.4 4.6-3.4 0.7-6.6 1.2-10 1.2zm11.6-7v-17.8h6.6v18.8l-6.6-1zm41.5 7c-3.7 0-7.1-0.6-10.2-1.8s-5.9-3-8.2-5.2-4.1-4.8-5.3-7.8c-1.3-3-1.9-6.3-1.9-9.8s0.6-6.8 1.9-9.8 3.1-5.6 5.3-7.8c2.3-2.2 5-4 8.1-5.2s6.6-1.8 10.2-1.8c3.7 0 7 0.6 10.1 1.8s5.8 2.9 8.1 5.2c2.3 2.2 4.1 4.8 5.3 7.8 1.3 3 1.9 6.3 1.9 9.8s-0.6 6.9-1.9 9.8c-1.3 3-3.1 5.6-5.3 7.8-2.3 2.2-5 4-8.1 5.2-3 1.2-6.3 1.8-10 1.8zm0-6.1c2.7 0 5.1-0.5 7.4-1.4s4.3-2.2 6-3.9 3-3.7 3.9-5.9 1.4-4.7 1.4-7.4-0.5-5.1-1.4-7.4c-0.9-2.2-2.2-4.2-3.9-5.9s-3.7-3-6-3.9-4.7-1.4-7.4-1.4-5.2 0.5-7.5 1.4-4.3 2.2-6 3.9-3.1 3.7-4 5.9-1.4 4.7-1.4 7.4 0.5 5.1 1.4 7.4c0.9 2.2 2.3 4.2 4 5.9s3.7 3 6 3.9 4.8 1.4 7.5 1.4zm31.2 5.5v-4.6l32.4-40.2 0.8 2.7h-32.7v-6h38.9v4.7l-32.3 40.2-0.8-2.7h34v6h-40.3v-0.1zm70.7 0.6c-3.7 0-7.1-0.6-10.2-1.8s-5.9-3-8.2-5.2-4.1-4.8-5.3-7.8c-1.3-3-1.9-6.3-1.9-9.8s0.6-6.8 1.9-9.8 3.1-5.6 5.3-7.8c2.3-2.2 5-4 8.1-5.2s6.6-1.8 10.2-1.8c3.7 0 7 0.6 10.1 1.8s5.8 2.9 8.1 5.2c2.3 2.2 4.1 4.8 5.3 7.8 1.3 3 1.9 6.3 1.9 9.8s-0.6 6.9-1.9 9.8c-1.3 3-3.1 5.6-5.3 7.8-2.3 2.2-5 4-8.1 5.2-3 1.2-6.3 1.8-10 1.8zm0-6.1c2.7 0 5.1-0.5 7.4-1.4s4.3-2.2 6-3.9 3-3.7 3.9-5.9 1.4-4.7 1.4-7.4-0.5-5.1-1.4-7.4c-0.9-2.2-2.2-4.2-3.9-5.9s-3.7-3-6-3.9-4.7-1.4-7.4-1.4-5.2 0.5-7.5 1.4-4.3 2.2-6 3.9-3.1 3.7-4 5.9-1.4 4.7-1.4 7.4 0.5 5.1 1.4 7.4c0.9 2.2 2.3 4.2 4 5.9s3.7 3 6 3.9 4.8 1.4 7.5 1.4z"
    />
    <path
      fill="#f6a7b5"
      d="m9.8 80 11.9-21.3 11.1 15.1-8.3 14.7-14.7-8.5zm63-4.7-6.6 10.2 9.3-5.4-2.7-4.8zm-30.2-53.7-19.5 34.6 11.1 15.1 18.2-32.3-9.8-17.4zm12.8 17.4 17.5 31.2 11.9-18.3v-26.5l-16.4-9.6-13 23.2zm-19.5 34.7 15.1 20.6 10.1-5.9 10.2-15.7-17.4-31-18 32zm6.7-57.4 6.6-11.7-6.5-3.8-6.6 3.9 6.5 11.6zm31.8 56.5 3.3 5.9 7.1-4.2v-17.8l-10.4 16.1zm-39.9 3.4-7.7 13.7 15.9 9.3 6.1-3.6-14.3-19.4zm-33.9-50.8 20.9 28.5 19.7-35-7.4-12.9-33.2 19.4zm50.8-19.5-7.3 13 9.8 17.4 12.3-21.9-14.8-8.5zm-31.4 50.5-19.4-26.6v44.8l7 4.1 12.4-22.3z"
    />
  </svg>
)

export { LogoPornSvg }
