export enum AdvertSize {
  S380x380 = '380x380',
  S500x200 = '500x200',
  S990x280 = '990x280',
  S1920x1500 = '1920x1500',
}

export enum AdvertPosition {
  Branding = 'branding',
  BottomPopUp = 'bottomPopUp',
  MiddleList = 'middleList',
}

export interface Advert {
  readonly position: AdvertPosition
}
