import React from 'react'
import { SessionId } from './model'
import { obtainSessionId } from './sessionId'

interface UserContextValue {
  sessionId: SessionId
}

const UserContext = React.createContext<UserContextValue>({
  sessionId: obtainSessionId(),
})

export default UserContext
