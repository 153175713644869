import styled from 'styled-components'

export const Spinner = styled.div`
  position: relative;
  min-height: 100px;

  &:before {
    position: absolute;
    top: calc(50% - 10px - 2px);
    left: calc(50% - 10px - 2px);
    width: 20px;
    height: 20px;
    border: 2px solid currentColor;
    border-top-color: transparent;
    animation: AnimateLoading 0.5s infinite linear;
    border-radius: 50%;
    content: '';
    pointer-events: none;
  }
`
