import React, { useCallback, CSSProperties, useContext } from 'react'
import { useInView } from 'react-intersection-observer'
import _ from 'lodash'
import type { VirtualItem } from '@tanstack/virtual-core'
import { File } from '../../../modules/file/model'
import { Advert as AdvertType } from '../../../modules/advert/model'
import Skeleton from '../Skeleton'
import { isAdvert, isFile, shouldDisplayThumbnail } from '../View/helpers'
import Thumb from '../View/Thumb'
import { Name } from '../View/Name'
import { Properties } from '../View/Properties'
import { ConfigContext, ConfigContextValue } from '../../../modules/config/context'
import theme from '../../../constants/GlobalTheme'
import { Item, RowWrapper } from './styled'

interface Props {
  items: (File | AdvertType)[]
  virtualRow: VirtualItem<unknown>
  index: number
  columns: number
  itemWidth: number
  gridGap: number
  allowSkeletons: boolean
  listWrapperRef: HTMLDivElement | null
  visibleRows: Record<number, boolean>
  updateVisibleRows: (id: number, value: boolean) => void
}

const renderItem = (
  config: ConfigContextValue,
  allowSkeletons: boolean,
  itemWidth: number,
  item: File | AdvertType | undefined,
  index: number
): JSX.Element | null => {
  switch (true) {
    case isFile(item):
      const file = item as File
      const fileType = file.hash?.contentType
      return (
        <Item
          key={index}
          className={!shouldDisplayThumbnail(file, config.service) ? 'no-thumb' : ''}
          data-testid={`fileList-item-${fileType}`}
        >
          <Thumb file={file} itemWidth={itemWidth} itemRadius={0} />
          <Name file={file} isGridView={true} />
          <Properties file={file} />
        </Item>
      )
    case allowSkeletons:
      return <Skeleton key={index} style={{ width: 100 + '%' }} />
    default:
      return null
  }
}

const ViewGrid: React.FC<Props> = ({
  allowSkeletons,
  columns,
  gridGap,
  index,
  items,
  itemWidth,
  listWrapperRef,
  updateVisibleRows,
  virtualRow,
  visibleRows,
}) => {
  const { ref: inViewRef, inView } = useInView({
    threshold: 0,
    triggerOnce: false,
  })
  const rowIndexes = _.range(index, index + columns)
  const listWrapper = listWrapperRef?.childNodes[0] as HTMLElement
  const listClientRect = listWrapper?.getBoundingClientRect()
  const config = useContext(ConfigContext)
  const inViewTypedRef = inViewRef as React.Ref<Element | Node | undefined>
  const measureElementTypedRef = virtualRow.measureElement as React.Ref<HTMLDivElement>

  const style: CSSProperties = {
    width: '100%',
    transform: `translateY(${virtualRow.start}px)`,
    zIndex: 1,
    background: theme.color.bg.main,
  }

  const fixedAdStyle: CSSProperties = {
    ...style,
    position: 'fixed',
    inset: 'initial',
    bottom: 0,
    transform: 'translateY(0)',
    zIndex: 0,
    width: listClientRect?.width,
  }

  const checkShouldFixAd = () => {
    const nextItem = items[index + 1]
    const nextItemIsAd = isAdvert(nextItem)
    const isNextNodeVisible = visibleRows[index + 1]
    const isPrevNodeVisible = visibleRows[index - 1]
    const isAdVisible = nextItemIsAd && inView
    const shouldFixAd = isAdVisible && !isNextNodeVisible && isPrevNodeVisible
    return !!shouldFixAd
  }

  const combinedRef = useCallback<React.RefCallback<HTMLDivElement>>(
    (node) => {
      if (_.isFunction(inViewTypedRef)) inViewTypedRef(node)
      if (_.isFunction(measureElementTypedRef)) measureElementTypedRef(node)
    },
    [inViewRef, measureElementTypedRef]
  )

  return (
    <RowWrapper
      ref={combinedRef}
      $itemWidth={itemWidth}
      $gridGap={gridGap}
      $gridColumns={columns}
      style={checkShouldFixAd() ? fixedAdStyle : style}
    >
      {rowIndexes.map((index) => {
        updateVisibleRows(index, inView)
        return renderItem(config, allowSkeletons, itemWidth, items[index], index)
      })}
    </RowWrapper>
  )
}

export default ViewGrid
