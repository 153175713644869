import React, { useEffect, useRef } from 'react'
import { useLocation, useNavigationType, useSearchParams } from 'react-router-dom'
import _ from 'lodash'
import { Action } from 'history'
import { useStore } from '../../hooks/useStore'
import SearchStore from '../../modules/search/store'
import LayoutHeader from '../../components/Layout/Header'
import AdvertStore from '../../modules/advert/store'
import Advert from '../../components/Advert'
import { AdvertPosition } from '../../modules/advert/model'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import { createAppSearchParams, createInitialAppSearchParams, createSearchURL, URLParamName } from './searchParams'
import HomeRoute from './Home'
import FileListRoute from './FileList'

const Index: React.FC = () => {
  const [urlSearchParams, navigate] = useSearchParams()
  const navigationType = useNavigationType()
  const loc = useLocation()
  const searchStore = useStore(SearchStore)
  const initialParams = createInitialAppSearchParams(urlSearchParams)
  const fileListWrapperRef = useRef<HTMLDivElement | null>(null)
  useDocumentTitle(searchStore.searchQuery)

  // load parameters from URL if needed
  useEffect(() => {
    // TODO because of navigate from porn disclaimer - find better way
    if (navigationType === Action.Replace) {
      SearchStore.setSearchQuery(initialParams.query)
      SearchStore.setSearchFilter(initialParams.filter)
    }
  }, [])

  // load state from URL when first visit and keep URL synced with state
  useEffect(() => {
    if (initialParams.query != searchStore.searchQuery || !_.isEqual(initialParams.filter, searchStore.searchFilter)) {
      const url = createSearchURL(createAppSearchParams(searchStore.searchQuery, searchStore.searchFilter))
      navigate(url, { replace: false })
    }
  }, [searchStore.searchQuery, searchStore.searchFilter])

  useEffect(() => {
    if (navigationType === Action.Pop) {
      SearchStore.set({
        searchQuery: initialParams.query,
        searchFilter: initialParams.filter,
      })
    }
  }, [loc.search])

  // reset search store on component unmount
  useEffect(
    () => () => {
      SearchStore.reset()
      AdvertStore.setAreAllowed(false)
    },
    []
  )

  return urlSearchParams.has(URLParamName.Query) || searchStore.searchQuery ? (
    <>
      <LayoutHeader
        withSearch={true}
        outlet={
          // it's better to provide ref from parent because it's available at first render inside FileList
          <div ref={fileListWrapperRef}>
            <FileListRoute containerRef={fileListWrapperRef} />
          </div>
        }
      />
      <Advert position={AdvertPosition.Branding} />
      <Advert position={AdvertPosition.BottomPopUp} isDynamic={true} isCloseable={true} />
    </>
  ) : (
    <HomeRoute />
  )
}

export default Index
