import { Service } from './model'

const STORAGE_KEY = 'theme'

export enum Theme {
  Light = 'light',
  Dark = 'dark',
  Porn = 'porn',
}

export const getTheme = (service: Service): Theme => {
  if (service === Service.Pinkfile) {
    return Theme.Porn
  }

  const value = localStorage.getItem(STORAGE_KEY)

  if (value === null) {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return Theme.Dark
    }

    return Theme.Light
  }

  if (!Object.values(Theme).includes(value as Theme)) {
    return Theme.Light
  }

  return value as Theme
}

export const setTheme = (theme: Theme): void => {
  localStorage.setItem(STORAGE_KEY, theme)
}

function setMetaThemeColor(theme: Theme) {
  const tag = document.querySelectorAll('meta[name="theme-color"]')
  if (tag.length) {
    tag[0].setAttribute('content', theme === Theme.Light ? '#f7f7f7' : '#1a1a1a')
  }
}

export const setThemeBodyClass = (theme: Theme): void => {
  if (theme === Theme.Porn) {
    document.body.classList.add('pink-theme')
    document.documentElement.style.setProperty('color-scheme', 'dark')
  } else if (theme === Theme.Light) {
    document.body.classList.remove('dark-theme')
    document.body.classList.remove('pink-theme')
    document.documentElement.style.setProperty('color-scheme', 'light')
  } else {
    document.body.classList.add('dark-theme')
    document.documentElement.style.setProperty('color-scheme', 'dark')
  }

  setMetaThemeColor(theme)
}
