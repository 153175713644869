import ArrowsSvg from './fileTemplate/Arrows'
import BrowserSvg from './fileTemplate/Browser'
import CaretDownSvg from './fileTemplate/CaretDown'
import CheckSvg from './fileTemplate/Check'
import CheckCircleSvg from './fileTemplate/CheckCircle'
import CloseSvg from './fileTemplate/Close'
import CloseCircleSvg from './fileTemplate/CloseCircle'
import CloudflareSvg from './fileTemplate/Cloudflare'
import DurationSvg from './fileTemplate/Duration'
import FileSvg from './fileTemplate/File'
import FilterSvg from './fileTemplate/Filter'
import FlagCZSvg from './fileTemplate/FlagCZ'
import FlagENSvg from './fileTemplate/FlagEN'
import FlagPLSvg from './fileTemplate/FlagPL'
import FlagSKSvg from './fileTemplate/FlagSK'
import HostSvg from './fileTemplate/Host'
import MenuSvg from './fileTemplate/Menu'
import MoreSvg from './fileTemplate/More'
import PlaySvg from './fileTemplate/Play'
import PlaySolidSvg from './fileTemplate/PlaySolid'
import PornSvg from './fileTemplate/Porn'
import SearchSvg from './fileTemplate/Search'
import SearchNoResultsSvg from './fileTemplate/SearchNoResults'
import SizeSvg from './fileTemplate/Size'
import ThemeLightSvg from './fileTemplate/ThemeLight'
import ThemeDarkSvg from './fileTemplate/ThemeDark'
import ThumbsDown from './fileTemplate/ThumbsDown'
import ThumbsUp from './fileTemplate/ThumbsUp'
import TypeArchiveSvg from './fileTemplate/TypeArchive'
import TypeAudioSvg from './fileTemplate/TypeAudio'
import TypeDocumentSvg from './fileTemplate/TypeDocument'
import TypeImageSvg from './fileTemplate/TypeImage'
import TypeLiveDmSvg from './fileTemplate/TypeLiveDm'
import TypeLiveMachrSvg from './fileTemplate/TypeLiveMachr'
import TypeVideoSvg from './fileTemplate/TypeVideo'

const Icon = {
  Arrows: ArrowsSvg,
  Browser: BrowserSvg,
  CaretDown: CaretDownSvg,
  Check: CheckSvg,
  CheckCircle: CheckCircleSvg,
  Close: CloseSvg,
  CloseCircle: CloseCircleSvg,
  Cloudflare: CloudflareSvg,
  Duration: DurationSvg,
  File: FileSvg,
  Filter: FilterSvg,
  FlagCZ: FlagCZSvg,
  FlagEN: FlagENSvg,
  FlagPL: FlagPLSvg,
  FlagSK: FlagSKSvg,
  Host: HostSvg,
  Menu: MenuSvg,
  More: MoreSvg,
  Play: PlaySvg,
  PlaySolid: PlaySolidSvg,
  Porn: PornSvg,
  Search: SearchSvg,
  SearchNoResults: SearchNoResultsSvg,
  Size: SizeSvg,
  ThemeDark: ThemeDarkSvg,
  ThemeLight: ThemeLightSvg,
  ThumbsDown: ThumbsDown,
  ThumbsUp: ThumbsUp,
  TypeArchive: TypeArchiveSvg,
  TypeAudio: TypeAudioSvg,
  TypeDocument: TypeDocumentSvg,
  TypeImage: TypeImageSvg,
  TypeLiveDm: TypeLiveDmSvg,
  TypeLiveMachr: TypeLiveMachrSvg,
  TypeVideo: TypeVideoSvg,
}

export default Icon
