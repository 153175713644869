import styled from 'styled-components'

export const Banner = styled.div`
  display: grid;
  max-width: 990px;
  box-sizing: border-box;
  align-items: center;
  padding: 5px;
  margin: 0 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  grid-template-columns: 120px 1fr;

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
    grid-template-columns: 180px 1fr;
  }

  > div:first-child {
    text-align: center;
  }
  svg {
    width: 150px;
    max-width: 100%;
    height: 50px;
  }
`
