import * as Sentry from '@sentry/browser'
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { toBlobURL } from '@ffmpeg/util'
import type { FFMessageLoadConfig } from '@ffmpeg/ffmpeg/dist/esm/types'
import { TNullable } from '../../types'
import { isMobileSafari } from '../../utils/browser.utils'

export const ffmpegShouldTranscode = (): boolean => true

export const ffmpegShouldLog = (): boolean => true

function customLog(...args) {
  const formattedArgs = args.map((arg) => {
    if (typeof arg === 'object') {
      return JSON.stringify(arg)
    }
    return arg.toString()
  })

  const formattedString = formattedArgs.join(' ')
  return formattedString
}

const myObject = { key: 'value', anotherKey: 42 }
const myArray = [1, 2, 3]
const myNumber = 42
const myBoolean = true

const message = customLog('Object:', myObject, 'Array:', myArray, 'Number:', myNumber, 'Boolean:', myBoolean)
console.log(message) // Output: "Object: {"key":"value","anotherKey":42} Array: [1,2,3] Number: 42 Boolean: true"

export const ffmpegLog = <T extends unknown[]>(...args: T): void => {
  if (!ffmpegShouldLog()) return
  // eslint-disable-next-line no-console
  console.log(...args)
  const log = document.getElementById('log')
  let content = customLog(args) + '<br>' + log.innerHTML
  log.innerHTML = content
}

const canPlayWebM = () => {
  const video = document.createElement('video')
  return !!video.canPlayType && video.canPlayType('video/webm; codecs="vp9"') !== '' // CDN uses vp9 codec for previewer
}

export const shouldLoadFfmpeg = (isMobileSafari() && !canPlayWebM()) || ffmpegShouldTranscode()

export const loadFFmpeg = async (
  isLoading: React.MutableRefObject<boolean>,
  loadsCount: React.MutableRefObject<number>
): Promise<TNullable<FFmpeg>> => {
  // block concurrent loads and try it maximum 4 times (no need to load it over and over in case of error)
  if (isLoading.current || loadsCount.current > 4) {
    return null
  }
  isLoading.current = true
  loadsCount.current++

  try {
    // see https://ffmpegwasm.netlify.app/docs/overview
    const ffmpegInstance = new FFmpeg()
    ffmpegInstance.on('log', ffmpegLog)
    await ffmpegInstance.load({
      // this may look like a bad idea, but since this code runs in workers
      // it requires special CORS headers for us and CDN as well,
      // so it's just easier to load it as BLOB
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/SharedArrayBuffer
      coreURL: await toBlobURL('/ffmpeg-core.js', 'text/javascript'),
      wasmURL: await toBlobURL(window.Config.FFMPEG_WASM, 'application/wasm'), // '/ffmpeg-core.wasm'
      workerURL: await toBlobURL('/ffmpeg-core.worker.js', 'text/javascript'),
      thread: true,
    } as FFMessageLoadConfig & { thread: boolean })
    ffmpegLog('loadFFmpeg:: lib loaded successfully')
    // it is weird but the docs say thread: true is required for multi-thread, but the interface does not have it. Anyway just to be sure
    return ffmpegInstance
  } catch (e) {
    ffmpegLog('loadFFmpeg:: load error ffmpgg', e)
    Sentry.captureException(e)
    return null
  } finally {
    isLoading.current = false
  }
}

export const reloadFFmpeg = async (ffmpegInstance: TNullable<FFmpeg>) => {
  try {
    if (!ffmpegInstance) return false
    ffmpegInstance.terminate()
    return await ffmpegInstance.load()
  } catch (e) {
    ffmpegLog('reloadFFmpeg:: reload failed', e)
    Sentry.captureException(e)
    return false
  }
}
