import { AdvertPosition } from './model'
import AdvertStore from './store'

const STORAGE_KEY = 'advertVisibility'
const INITIAL_NUMBER = '4'

const key = (position: AdvertPosition) => STORAGE_KEY + '_' + position

export const hasVisibilityCounter = (position: AdvertPosition) => localStorage.getItem(key(position)) !== null

export const setVisibilityCounter = (position: AdvertPosition) => localStorage.setItem(key(position), INITIAL_NUMBER)

export const decreaseVisibilityCounter = () => {
  Object.values(AdvertPosition).forEach((position: AdvertPosition) => {
    const adKey = key(position)
    const value = localStorage.getItem(adKey)

    if (value !== null) {
      const intValue = parseInt(value)

      if (1 >= intValue) {
        localStorage.removeItem(adKey)
        AdvertStore.setVisibility(position, true)
      } else {
        localStorage.setItem(adKey, (intValue - 1).toString())
      }
    }
  })
}
