import React, { useContext, useEffect, useRef, useState } from 'react'
import { Thumbnail } from '../../../../modules/file/model'
import { SlideshowContext } from '../../../../modules/thumb'
import Icon from '../../../Icons'
import useSwipeActivated from '../../../../hooks/useSwipeActivated'
import { Image, ThumbWrapper } from './styled'

const MAX_REPETITIONS = 2

interface Props {
  id: string
  thumbnail: Thumbnail
  alt: string
  isStreamable: boolean
}

const SlideShow: React.FC<Props> = ({ id, thumbnail, alt, isStreamable }) => {
  const { currentActiveId, setActiveId } = useContext(SlideshowContext)

  const imagesCount = thumbnail.images.length
  const defaultIndex = Math.min(thumbnail.defaultIndex, imagesCount - 1)
  const [currentImage, setCurrentImage] = useState(defaultIndex)
  const interval = useRef<number>()

  const start = () => {
    setActiveId(id)
    setCurrentImage(0)
  }

  const stop = () => {
    if (currentActiveId === id) {
      setActiveId(null)
      setCurrentImage(defaultIndex)
    }
  }

  const touchEvents = useSwipeActivated(start)

  // classic repetition, with interval
  useEffect(() => {
    if (currentActiveId !== id) {
      if (interval.current) {
        clearInterval(interval.current)
      }
      setCurrentImage(defaultIndex)
      return
    }

    let next = currentImage
    let repetition = 1

    interval.current = window.setInterval(() => {
      if (currentActiveId !== id) {
        clearInterval(interval.current)
        setCurrentImage(defaultIndex)
        return
      }

      next = next + 1

      if (next >= imagesCount) {
        if (repetition >= MAX_REPETITIONS) {
          clearInterval(interval.current)
          stop()
        } else {
          next = 0
          repetition++
          setCurrentImage(next)
        }
      } else {
        setCurrentImage(next)
      }
    }, 700)

    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
      setCurrentImage(defaultIndex)
    }
  }, [currentActiveId])

  return (
    <ThumbWrapper className="wrapper" onMouseEnter={start} onMouseLeave={stop} {...touchEvents}>
      <Image src={thumbnail.images[currentImage].url} alt={alt} loading="lazy" />
      {isStreamable && currentActiveId !== id && (
        <div className="play">
          <Icon.PlaySolid />
        </div>
      )}
    </ThumbWrapper>
  )
}

export default SlideShow
