import React from 'react'
import * as Sentry from '@sentry/browser'
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile } from '@ffmpeg/util'
import { FFmpegContextValue, ffmpegLog, ffmpegShouldLog, ffmpegShouldTranscode } from '../../../../modules/thumb'
import { TNullable, TUndefined } from '../../../../types'

export const createDefaultAttributes = (poster: TUndefined<string>): React.VideoHTMLAttributes<HTMLVideoElement> => ({
  poster,
  preload: 'metadata', // keep it lazy for video content
  playsInline: true, // https://webkit.org/blog/6784/new-video-policies-for-ios/
  muted: true, // necessary for playing without user interaction
  loop: true,
  autoPlay: false,
  controls: false,
})

// https://github.com/facebook/react/issues/10389
export const applyAttributes = (el: HTMLVideoElement, attrs: React.VideoHTMLAttributes<HTMLVideoElement>): void => {
  Object.entries(attrs).forEach(([key, value]) => {
    if (value) {
      el.setAttribute(key, typeof value === 'boolean' ? key : value)
    }
  })
}
export const hasMediaError = (el: HTMLVideoElement) => el.error instanceof MediaError
export const isMediaError = (el: HTMLVideoElement, code: number) => code == el.error?.code
export const hasOutOfMemoryError = (e: Error) =>
  e instanceof Error &&
  (e.message?.includes('memory access out of bounds') || e.message?.includes('Out of bounds') || e.message?.includes('memory'))
export const isMediaNotSupportedError = (el: HTMLVideoElement) =>
  hasMediaError(el) && isMediaError(el, MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED)
export const isConverted = (el: HTMLVideoElement) => el.src.startsWith('blob:')
export const shouldTranscode = (el: HTMLVideoElement) => (isMediaNotSupportedError(el) || ffmpegShouldTranscode()) && !isConverted(el)

// some "preview.video" URLs not exists on CDN
export const isNotFoundError = (el: HTMLVideoElement) => isMediaNotSupportedError(el) && !isConverted(el)

const videoExistsInWasm = async (ffmpeg: TNullable<FFmpeg>, fileId: string) => {
  if (!ffmpeg || !fileId) return false
  try {
    const dirFiles = await ffmpeg.listDir('/')
    const names = dirFiles.map((f) => f.name)
    const fileExists = names.includes(fileId + '.mp4')
    ffmpegLog('videoExistsInWasm:: fileExists', fileExists, fileId)
    return fileExists
  } catch (e) {
    ffmpegLog('videoExistsInWasm:: error', e)
    return false
  }
}

export const videoReset = async (el: HTMLVideoElement, fileId: string, url: string, ffmpegContext: FFmpegContextValue) => {
  const { ffmpeg, deleteVideo } = ffmpegContext

  ffmpegLog('videoReset:: el.error before reset', el.error)
  ffmpegLog('videoReset:: el.src before reset', el.src)
  // delete wrongly transcoded video if exists
  if (ffmpeg) {
    deleteVideo(fileId)
    const fileExists = await videoExistsInWasm(ffmpeg, fileId)
    if (fileExists) await ffmpeg.deleteFile(fileId + '.mp4')
  }
  // revoke object url and reset video source to original URL
  if (isConverted(el)) {
    URL.revokeObjectURL(el.src)
    el.src = url
  }
  ffmpegLog('videoReset:: el.error', el.error)
  ffmpegLog('videoReset:: el.src', el.src)
  el.load()
}

export const videoTranscode = async (
  ffmpegContext: FFmpegContextValue,
  el: HTMLVideoElement,
  url: string,
  fileId: string,
  args: string[]
): Promise<boolean> => {
  let { ffmpeg } = ffmpegContext
  try {
    if (!ffmpeg) {
      // our detection in FFmpegProvider has failed
      ffmpeg = await ffmpegContext.loadFFmpeg()
      if (!ffmpeg) {
        return false
      }
      ffmpegContext.setFFmpeg(ffmpeg)
    }
    if (!ffmpeg.loaded) return false
    ffmpegLog('videoTranscode:: transcoding video started: ', url, fileId)

    const fileNameMp4 = fileId + '.mp4'
    const startTime = performance.now()
    const webmContent = await fetchFile(url)
    await ffmpeg.writeFile(fileId, webmContent)

    try {
      await ffmpeg.exec(['-i', fileId, ...args, '-f', 'mp4', fileNameMp4])
    } finally {
      await ffmpeg.deleteFile(fileId)
      if (ffmpegShouldLog()) {
        const endTime = performance.now()
        const totalTime = Math.round(((endTime - startTime) / 1000) * 100) / 100
        ffmpegLog('videoTranscode:: Video transcoding took ' + totalTime + ' seconds', fileId, fileId)
        ffmpegLog('videoTranscode:: transcoding args: ', ...args)
      }
    }

    try {
      const data = await ffmpeg.readFile(fileNameMp4)
      const src = URL.createObjectURL(new Blob([data], { type: 'video/mp4' }))
      el.src = src
      ffmpegContext.storeVideo(fileId, src)
    } finally {
      await ffmpeg.deleteFile(fileNameMp4)
    }

    if (ffmpegShouldLog()) {
      const endTime = performance.now()
      const totalTime = Math.round(((endTime - startTime) / 1000) * 100) / 100
      ffmpegLog('videoTranscode:: Video transcoding took ' + totalTime + ' seconds', fileId)
    }

    ffmpegLog('videoTranscode:: el.src after transcode', el?.src, fileId)
    return true
  } catch (e) {
    ffmpegLog('videoTranscode:: videoTranscode error: ', e instanceof Error && e?.message, fileId)
    ffmpegLog('videoTranscode:: Video transcoding failed', e, fileId)

    // reset video so it can be transcoded again
    if (hasMediaError(el)) videoReset(el, fileId, url, ffmpegContext)

    // handle "RuntimeError: memory access out of bounds" error: reload ffmpeg instance
    if (hasOutOfMemoryError(e as Error)) {
      ffmpegLog('videoTranscode:: runtime memmory error: ', e instanceof Error && e.message, fileId)
      ffmpegContext.reloadFFmpeg()
    }

    Sentry.captureException(e)
    return false
  }
}

export const videoPlay = async (el: HTMLVideoElement, fileId: string, url: string, ffmpegContext: FFmpegContextValue) => {
  try {
    ffmpegLog('videoPlay:: src to play', el.src)
    await el.play()
  } catch (e) {
    ffmpegLog('videoPlay:: error: ', e instanceof Error && e?.message)
    ffmpegLog('videoPlay:: error src: ', el.src)
    if (isMediaNotSupportedError(el)) {
      ffmpegLog('videoPlay:: media error, resetting video')
      videoReset(el, fileId, url, ffmpegContext)
      return
    }
    Sentry.captureException(e)
  }
}

export const videoStop = (el: HTMLVideoElement) => {
  el.currentTime = 0
  el.pause()
}
