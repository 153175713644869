import { FFmpeg } from '@ffmpeg/ffmpeg'
import { useState, createContext, useLayoutEffect, useRef } from 'react'
import { TNullable, TUndefined } from '../../types'
import { reloadFFmpeg, shouldLoadFfmpeg, loadFFmpeg } from './ffmpeg'

interface ILastVisitedVideo {
  id: string
  url: string
  el: TNullable<HTMLVideoElement>
}

export type FFmpegContextValue = {
  ffmpeg: TNullable<FFmpeg>
  loadFFmpeg: () => Promise<TNullable<FFmpeg>>
  setFFmpeg: (ffmpeg: TNullable<FFmpeg>) => void
  reloadFFmpeg: () => Promise<TNullable<boolean>>
  setLastVisitedVideo: (id: string, url: string, el: TNullable<HTMLVideoElement>) => void
  lastVisitedVideo: TNullable<ILastVisitedVideo>
  storeVideo: (id: string, blob: string) => void
  getVideo: (id: string) => TUndefined<string>
  deleteVideo: (id: string) => void
}

export const FFmpegContext = createContext<FFmpegContextValue>({
  ffmpeg: null,
  loadFFmpeg: async () => null,
  reloadFFmpeg: async () => null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  setFFmpeg: (_: TNullable<FFmpeg>) => {},
  lastVisitedVideo: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLastVisitedVideo: () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  storeVideo: (id: string, src: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  getVideo: (id: string) => undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  deleteVideo: (id: string) => {},
})

export const FFmpegProvider = ({ children }: { children: JSX.Element }) => {
  const [ffmpeg, setFFmpeg] = useState<TNullable<FFmpeg>>(null)
  const isLoading = useRef(false)
  const lastVisitedRef = useRef<TNullable<ILastVisitedVideo>>(null)
  const loadsCount = useRef(0)
  const videoStorage = useRef(new Map<string, string>())

  useLayoutEffect(() => {
    if (!shouldLoadFfmpeg) return
    loadFFmpeg(isLoading, loadsCount).then(setFFmpeg)
  }, [])

  return (
    <FFmpegContext.Provider
      value={{
        ffmpeg,
        lastVisitedVideo: lastVisitedRef.current,
        loadFFmpeg: () => loadFFmpeg(isLoading, loadsCount),
        reloadFFmpeg: () => reloadFFmpeg(ffmpeg),
        setFFmpeg,
        setLastVisitedVideo: (id, url, el) => (lastVisitedRef.current = { id, url, el }),
        storeVideo: (id: string, blob: string) => videoStorage.current.set(id, blob),
        getVideo: (id: string) => videoStorage.current.get(id),
        deleteVideo: (id: string) => videoStorage.current.delete(id),
      }}
    >
      {children}
    </FFmpegContext.Provider>
  )
}
