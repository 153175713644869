import { useEffect, useState } from 'react'

interface Message {
  type: string
  data?: unknown
}

export const usePostMessage = (sourceId: string): Message | undefined => {
  const [message, setMessage] = useState<Message | undefined>(undefined)

  const handleEvent = (e: MessageEvent) => {
    const isOriginSame = e.origin === window.location.origin
    const isDataCorrect = e.data !== null && typeof e.data === 'object' && 'type' in e.data
    const isValidAdvertSearch =
      isDataCorrect && 'advertSearch' in e.data && new URLSearchParams(e.data.advertSearch).get('sourceId') === sourceId
    if (!isOriginSame || !isDataCorrect || !isValidAdvertSearch) {
      return
    }

    setMessage({
      type: e.data.type,
      data: e.data.data || undefined,
    })
  }

  useEffect(() => {
    window.addEventListener('message', handleEvent, false)

    return () => window.removeEventListener('message', handleEvent)
  }, [])

  return message
}
